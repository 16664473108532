import {
  faDownload,
  faEllipsisH,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Button,
  ContentLayout,
  Content,
  InputBox,
  ListTable,
  SearchFilter,
  Dropdown,
} from "@components";
import {
  ResultPagingResponse,
  TFilterSelectUpDown,
  IDBMarketingLog,
  IMarketingLogDetailResponse,
} from "@data";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import {states} from "@recoils";
import {adminAPI, excelUtil, styleUtils, utils} from "@utils";
import {MarketingAddModal} from "./MarketingAddModal";
import {MarketingClickListModal} from "./MarketingClickListModal";
import {MarketingDetailModal} from "./MarketingDetailModal";
import styles from "./style.module.scss";
import {deeplinkArr} from "utils/constant";

export const convertMarketingSelect: {
  [key in TFilterSelectUpDown]: string;
} = {
  up: "이상",
  down: "이하",
  equal: "같음",
};

export default function MarketingPage() {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [searchText, setSearchText] = useState("");
  const [listData, setListData] =
    useState<ResultPagingResponse<IDBMarketingLog[]>>();
  const [detailData, setDetailData] = useState<IMarketingLogDetailResponse>();
  const [clickNo, setClickNo] = useState<number>();
  const [isAddModal, setIsAddModal] = useState(false);

  async function getList({page = 1}: {page?: number} = {}) {
    try {
      const {data, success, message} =
        await adminAPI.marketing.apiGetMarketingLog({
          limit: 20,
          page,
          searchtxt: searchText,
        });
      if (success) {
        setListData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getList error", error);
    }
  }

  async function getDetail({no}: {no: number}) {
    try {
      const {data, success, message} =
        await adminAPI.marketing.apiGetMarketingLogDetail({
          no,
        });
      if (success) {
        setDetailData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getDetail error", error);
    }
  }

  async function deleteMarketing({no}: {no: number}) {
    try {
      if (!confirm("예약된 푸시를 삭제하시겠습니까?")) {
        return;
      }
      const {success, message} = await adminAPI.marketing.apiDeleteMarketingLog(
        {no},
      );
      if (success) {
        getList({page: listData?.page?.cur || 1});
      }
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다",
      });
    } catch (error) {
      console.error("deleteMarketing error", error);
    }
  }

  async function marketingListExcelDownload() {
    try {
      const {success, data, message} =
        await adminAPI.marketing.getExportMarketingList({
          searchtxt: searchText,
        });
      if (success) {
        excelUtil.excelDownload({
          excelData: data.result,
          filename: "marketingFile",
        });
      } else {
        return setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.log("marketingListExcelDownload error", error);
    }
  }

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <ContentLayout title="마케팅알림">
        <SearchFilter
          left={[
            <InputBox
              key={1}
              value={searchText}
              onValue={setSearchText}
              onEnter={getList}
              placeholder={"타이틀, 내용 검색"}
              rightContent={
                <div style={{display: "flex"}} onClick={() => getList()}>
                  <FontAwesomeIcon icon={faSearch} />
                </div>
              }
            />,
          ]}
          right={[
            <Button
              key={3}
              text="내보내기"
              icon={<FontAwesomeIcon icon={faDownload} />}
              onClick={marketingListExcelDownload}
              backgroundColor={styleUtils.COLOR.blueDark}
              fontColor={styleUtils.COLOR.white}
              borderRadius={1}
              fontSize={12}
            />,
            <Button
              key={2}
              text="푸시등록"
              backgroundColor={styleUtils.COLOR.green}
              fontColor={styleUtils.COLOR.white}
              borderRadius={1}
              fontSize={12}
              onClick={() => setIsAddModal(true)}
            />,
          ]}
        />

        <Content>
          <ListTable paging={listData?.page} getList={getList}>
            <colgroup>
              <col style={{width: "15%"}} />
              <col style={{width: "25%"}} />
              <col style={{width: "10%"}} />
              <col style={{width: "10%"}} />
              <col style={{width: "10%"}} />
              <col style={{width: "10%"}} />
              <col style={{width: "5%"}} />
              <col style={{width: "5%"}} />
            </colgroup>
            <thead>
              <tr>
                <th>타이틀</th>
                <th>내용</th>
                <th>발송수량</th>
                <th>발송성공</th>
                <th>클릭수량</th>
                <th>발송일시</th>
                <th>발송여부</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {(listData?.result || []).map((item, index) => {
                const isLoding =
                  item.send_yn === "Y" &&
                  moment().diff(item.updatedAt, "m") < 5 &&
                  Number(item.send_cnt || 0) === 0;
                return (
                  <tr key={index}>
                    <td className={styles.overflowEllipsis}>
                      <a onClick={() => !!item.no && getDetail({no: item.no})}>
                        {item.title}
                      </a>
                    </td>
                    <td className={styles.content}>
                      <a onClick={() => !!item.no && getDetail({no: item.no})}>
                        {item.content}
                      </a>
                    </td>
                    <td>
                      {isLoding
                        ? "발송중"
                        : (item.send_cnt || 0).toLocaleString()}
                    </td>
                    <td>
                      {(item.push_success_cnt || 0).toLocaleString()}
                      <span className={styles.span}>
                        (
                        {(
                          ((item.push_success_cnt || 0) /
                            (item.send_cnt || 1)) *
                          100
                        ).toFixed(1)}
                        %)
                      </span>
                    </td>
                    <td
                      onClick={e => {
                        utils.handleClickBubbleAndCapture(e);
                        setClickNo(item.no);
                      }}>
                      {(item.click_cnt || 0).toLocaleString()}
                      <span className={styles.span}>
                        (
                        {(
                          ((item.click_cnt || 0) /
                            (item.push_success_cnt || item.send_cnt || 1)) *
                          100
                        ).toFixed(1)}
                        %)
                      </span>
                    </td>
                    <td>
                      {item.reservation_datetime ? (
                        <>
                          {moment(item.reservation_datetime).format(
                            "YYYY-MM-DD HH:mm",
                          )}
                        </>
                      ) : (
                        moment(item.createdAt).format("YYYY-MM-DD HH:mm")
                      )}
                    </td>
                    <td>
                      <span>{item.send_yn}</span>
                    </td>
                    <td className={["visible", styles.button].join(" ")}>
                      <Dropdown
                        content={
                          <>
                            <FontAwesomeIcon
                              icon={faEllipsisH}
                              className={styles.icon}
                            />
                          </>
                        }
                        options={[
                          {
                            label: "삭제",
                            value: "logout",
                            onClick: () => {
                              if (item.no) {
                                deleteMarketing({no: item.no});
                              }
                            },
                          },
                        ]}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </ListTable>
        </Content>
      </ContentLayout>
      {!!detailData && (
        <MarketingDetailModal
          data={detailData}
          close={() => setDetailData(undefined)}
          isVisible={!!detailData}
          deeplinkArr={deeplinkArr}
        />
      )}
      {!!clickNo && (
        <MarketingClickListModal
          isVisible={!!clickNo}
          marketing_no={clickNo}
          close={() => setClickNo(undefined)}
        />
      )}
      <MarketingAddModal
        isVisible={isAddModal}
        close={() => setIsAddModal(false)}
        getList={getList}
        deeplinkArr={deeplinkArr}
      />
    </>
  );
}
