import {Routes, Route, useNavigationType, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import * as Pages from "@pages";
import {initMenu} from "components/Layout/Sidebar";
import {utils} from "@utils";
import {ScreenId} from "@assets";
import {useLogin} from "@hook";

interface IRoute {
  path: ScreenId;
  element: JSX.Element;
}
const initRouteList: IRoute[] = [
  {path: ScreenId.Dashboard, element: <Pages.DashboardPage />},
  {path: ScreenId.Kpi, element: <Pages.KpiPage />},
  {path: ScreenId.Partner, element: <Pages.PartnerPage />},
  {path: ScreenId.OrderLog, element: <Pages.OrderLogPage />},
  {path: ScreenId.SmsLog, element: <Pages.SMSLogPage />},
  {path: ScreenId.Marketing, element: <Pages.MarketingPage />},
  {path: ScreenId.PaymentLog, element: <Pages.PaymentLogPage />},
  {path: ScreenId.ItemQnA, element: <Pages.ItemQnaPage />},
  {path: ScreenId.MemberQnA, element: <Pages.MemberQnaPage />},
  {path: ScreenId.HomeTab, element: <Pages.HomeTabPage />},
  {path: ScreenId.Notice, element: <Pages.NoticePage />},
  {path: ScreenId.Review, element: <Pages.ReviewPage />},
  {path: ScreenId.Faq, element: <Pages.FaqPage />},
  {path: ScreenId.Order, element: <Pages.OrderPage />},
  {path: ScreenId.Banner, element: <Pages.BannerPage />},
  {path: ScreenId.Coupon, element: <Pages.CouponPage />},
  {path: ScreenId.EventPopup, element: <Pages.EventPopupPage />},
  {path: ScreenId.Member, element: <Pages.MemberPage />},
  {path: ScreenId.Section, element: <Pages.SectionPage />},
  {path: ScreenId.Attendance, element: <Pages.AttendancePage />},
  {path: ScreenId.Item, element: <Pages.ItemPage />},
  {path: ScreenId.ItemPriceChange, element: <Pages.ItemPriceChangePage />},
  {path: ScreenId.DeliveryDate, element: <Pages.DeliveryDatePage />},
  {path: ScreenId.Frequency, element: <Pages.FrequencyPage />},
  {path: ScreenId.Post, element: <Pages.PostPage />},
  {path: ScreenId.OrderProfit, element: <Pages.OrderProfitPage />},
  {
    path: ScreenId.MemberOutRequest,
    element: <Pages.MemberOutRequestPage />,
  },
  {
    path: ScreenId.StockExcelImport,
    element: <Pages.StockExcelImportPage />,
  },
  {
    path: ScreenId.ReOrderCountPage,
    element: <Pages.ReOrderCountPage />,
  },
  {
    path: ScreenId.CohortMonthly,
    element: <Pages.CohortMonthlyPage />,
  },
  {path: ScreenId.Grade, element: <Pages.GradePage />},
  {path: ScreenId.DynamicPricing, element: <Pages.DynamicPricingPage />},
];

const initMenuLevel = initMenu
  .reduce((acc, _menu) => {
    if ((_menu.detail || []).length > 0) {
      (_menu.detail || []).map(_detailMenu => {
        if (_detailMenu.link) {
          acc.push({path: _detailMenu.link, level: _detailMenu.level});
        }
      });
    } else if (_menu.link) {
      acc.push({path: _menu.link, level: _menu.level});
    }
    return acc;
  }, [] as {path: string; level: number[]}[])
  .filter(utils.isDefined);

export default function AdminRouteApp() {
  const {adminData} = useLogin();
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  const [routeList, setRouteList] = useState<IRoute[]>([]);

  useEffect(() => {
    if (action !== "POP") window.scrollTo(0, 0);
  }, [action, pathname]);

  useEffect(() => {
    const tempRouteList = initRouteList.filter(
      _route =>
        initMenuLevel.filter(
          _menu =>
            !!adminData?.level &&
            _menu.path === _route.path &&
            _menu.level.includes(adminData?.level),
        ).length > 0,
    );
    setRouteList(tempRouteList);
  }, [adminData]);

  return (
    <Routes>
      <Route
        path="/"
        element={(routeList || [])[0]?.element || <Pages.ErrorPage />}
      />
      {routeList.map((_route, i) => (
        <Route key={i} path={`/${_route.path}`} element={_route.element} />
      ))}
      <Route path="*" element={<Pages.ErrorPage />} />
    </Routes>
  );
}
