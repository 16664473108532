import React, {useEffect, useState} from "react";
import {
  Button,
  CheckBox,
  Content,
  ContentLayout,
  FileUploadButton,
  InputBox,
  ListTable,
  SearchFilter,
  SelectBox,
} from "@components";
import {
  ResultPagingResponse,
  TItemFilterSortType,
  IDBPartner,
  IViewProduct,
  TypeYN,
  IEditItemPrice,
} from "@data";
import {IFilterData} from "pages/ItemPage";
import {adminAPI, axiosUtil, excelUtil, styleUtils, utils} from "@utils";
import {useSetRecoilState} from "recoil";
import {states} from "@recoils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faSearch} from "@fortawesome/free-solid-svg-icons";
import styles from "./style.module.scss";
import {COLOR} from "utils/styleUtils";
import moment from "moment";

interface IChangeItemList extends IViewProduct {
  new_input_price: number;
  new_price: number;
  new_view_price: number;
  new_promotion_price: number | null;
  new_subscribe_price: number;
}

const LOCAL_STORAGE_KEYS = {
  ITEMS_PER_PAGE: "prices_itemsPerPage",
  PARTNERS: "prices_partners",
  SORTS: "prices_sorts",
  SEARCH_TEXT: "prices_search_text",
  CURRENT_PAGE: "prices_current_page",
};

export default function ItemPriceChangePage() {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [filter, setFilter] = useState<IFilterData>({});
  const [partnerList, setPartnerList] = useState<IDBPartner[]>([]);
  const [listData, setListData] =
    useState<ResultPagingResponse<IChangeItemList[]>>();
  const [percent, setPercent] = useState(0);
  const [selectNoList, setSelectNoList] = useState<number[]>([]);
  const [itemsPerPage, setItemsPerPage] = useState<number>(
    Number(localStorage.getItem(LOCAL_STORAGE_KEYS.ITEMS_PER_PAGE)) || 30,
  );
  const [partners, setPartners] = useState<string[]>([]);
  const [sorts, setSorts] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);

  // useEffect(() => {
  //   localStorage.setItem(
  //     LOCAL_STORAGE_KEYS.ITEMS_PER_PAGE,
  //     JSON.stringify(itemsPerPage),
  //   );
  // }, [itemsPerPage]);
  //
  // useEffect(() => {
  //   localStorage.setItem(LOCAL_STORAGE_KEYS.PARTNERS, JSON.stringify(partners));
  // }, [partners]);
  //
  // useEffect(() => {
  //   localStorage.setItem(LOCAL_STORAGE_KEYS.SORTS, JSON.stringify(sorts));
  // }, [sorts]);
  //
  // useEffect(() => {
  //   localStorage.setItem(LOCAL_STORAGE_KEYS.SEARCH_TEXT, searchText);
  // }, [searchText]);
  //
  // useEffect(() => {
  //   localStorage.setItem(
  //     LOCAL_STORAGE_KEYS.CURRENT_PAGE,
  //     JSON.stringify(currentPage),
  //   );
  // }, [currentPage]);

  const isAllSelected =
    (listData?.result || []).filter(
      item => !!item.no && !selectNoList.includes(item.no),
    ).length === 0;

  const sortArr: {label: string; value: TItemFilterSortType | ""}[] = [
    {label: "바코드순", value: "barcodeASC"},
    // {label: "바코드순(역순)", value: "barcodeDESC"},
    {label: "재고많은순", value: "stockDESC"},
    {label: "재고적은순", value: "stockASC"},
    {label: "등록순", value: "createdAtASC"},
    {label: "등록순(역순)", value: "createdAtDESC"},
    {label: "이름순", value: "itemNameASC"},
    {label: "이름순(역순)", value: "itemNameDESC"},
    {label: "저가순", value: "priceASC"},
    {label: "고가순", value: "priceDESC"},
  ];

  async function getList({page = 1}: {page?: number} = {}) {
    try {
      setSelectNoList([]);
      const partnerNumbers = partners.map(item => Number(item));

      const {success, message, data} = await adminAPI.item.getList({
        page,
        limit: itemsPerPage,
        sorts: sorts,
        partners: partnerNumbers,
        searchtxt: searchText,
        // ...filter,
      });
      if (success) {
        setListData({
          ...data,
          result: (data.result || [])
            .filter(_item => !!_item.no)
            .map(_item => ({
              ..._item,
              item_no: _item.no as number,
              new_price: _item.price || 0,
              new_view_price: _item.view_price || 0,
              new_input_price: _item.input_price || 0,
              new_promotion_price: _item.promotion_price || 0,
              new_subscribe_price: _item.subscribe_price || 0,
            })),
        });
        setSelectNoList(
          (data.result || []).map(_item => _item.no).filter(utils.isDefined),
        );
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getList error", error);
    }
  }

  async function getPartnerList() {
    try {
      const {data, success, message} =
        await adminAPI.partner.apiGetAllPartners();
      if (success) {
        setPartnerList(data.result);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getPartnerList error", error);
    }
  }

  function allSave() {
    try {
      const data = (listData?.result || [])
        .map(
          item =>
            ({
              item_no: item.no || 0,
              price: item.new_price,
              view_price: item.new_view_price,
              input_price: item.new_input_price,
              promotion_price: item.new_promotion_price,
              subscribe_price: item.new_subscribe_price,
            } as IEditItemPrice),
        )
        .filter(
          item => selectNoList.filter(no => item.item_no === no).length > 0,
        );

      updatePrice({data});
    } catch (error) {
      console.error("allSave error", error);
    }
  }

  async function updatePrice({data}: {data: IEditItemPrice[]}) {
    try {
      if (data.length === 0) {
        setAlertModal({
          isVisible: true,
          title: "수정된 내역이 없습니다.",
        });
        return;
      }
      if (!confirm(`${data.length}개의 상품의 가격을 변경하시겠습니까?`)) {
        getResetPage();
        return;
      }

      const {success, message} = await adminAPI.item.changeProductPrice({data});
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다",
      });
      if (success) {
        getResetPage();
      }
      return true;
    } catch (error) {
      console.error("updatePrice error", error);
      return false;
    }
  }

  function getResetPage() {
    getList({page: listData?.page?.cur});
  }

  function reset() {
    try {
      setListData({
        ...listData,
        result: (listData?.result || []).map(item => {
          const checked = selectNoList.filter(no => item.no === no).length > 0;
          if (checked) {
            return {
              ...item,
              new_promotion_price: null,
            } as IChangeItemList;
          }
          return item;
        }),
      });
      const data = (listData?.result || [])
        .map(
          item =>
            ({
              item_no: item.no || 0,
              price: item.new_price,
              view_price: item.new_view_price,
              input_price: item.new_input_price,
              promotion_price: item.new_promotion_price,
              subscribe_price: item.new_subscribe_price,
            } as IEditItemPrice),
        )
        .filter(
          item => selectNoList.filter(no => item.item_no === no).length > 0,
        );
    } catch (error) {
      console.error("reset error", error);
    }
  }

  async function changeViewYn({view_yn = "Y"}: {view_yn?: TypeYN} = {}) {
    try {
      if (selectNoList.length === 0) {
        setAlertModal({
          isVisible: true,
          title: "체크된 상품이 없습니다.",
        });
        return;
      }
      if (
        !confirm(
          `${selectNoList.length}개의 상품을 ${
            view_yn === "Y" ? "노출" : "비노출"
          }로 변경하시겠습니까?`,
        )
      ) {
        getResetPage();
        return;
      }

      const {success, message} = await adminAPI.item.toggleViewYnList({
        item_list: selectNoList,
        view_yn: "Y",
      });
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다",
      });
      if (success) {
        getResetPage();
      }
      return true;
    } catch (error) {
      console.error("changeViewYn error", error);
      return false;
    }
  }

  async function changeCouponUseableYn({
    coupon_useable,
  }: {
    coupon_useable: TypeYN;
  }) {
    try {
      if (selectNoList.length === 0) {
        setAlertModal({
          isVisible: true,
          title: "체크된 상품이 없습니다.",
        });
        return;
      }
      if (
        !confirm(
          `${selectNoList.length}개의 상품을 쿠폰사용여부 ${
            coupon_useable === "Y" ? "가능" : "불가능"
          }로 변경하시겠습니까?`,
        )
      ) {
        getResetPage();
        return;
      }

      const {success, message} = await adminAPI.item.toggleCouponUseableYnList({
        item_list: selectNoList,
        coupon_useable,
      });
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다",
      });
      if (success) {
        getResetPage();
      }
      return true;
    } catch (error) {
      console.error("changeViewYn error", error);
      return false;
    }
  }

  async function listExcelDownload() {
    try {
      const partnerNumbers = partners.map(item => Number(item));

      const {success, data, message} =
        await adminAPI.item.getPriceListExportExcel({
          partners: partnerNumbers,
          sorts: sorts,
          ...filter,
        });
      if (success) {
        excelUtil.excelDownload({
          excelData: data.result,
          filename: `product_item_list_${moment().format("YYYYMMDD_HHMMSS")}`,
        });
      } else {
        return setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.log("memberListExcelDownload error", error);
    }
  }

  useEffect(() => {
    getPartnerList();
  }, []);

  // useEffect(() => {
  //   getList();
  // }, [filter.partner, filter.sort]);

  useEffect(() => {
    getList({page: 1});
  }, [filter.partner, filter.sort, itemsPerPage, partners, sorts]);

  return (
    <>
      <ContentLayout title="가격관리">
        <SearchFilter
          left={[
            <SelectBox
              key={2}
              list={[
                {label: "30개씩 보기", value: "30"},
                {label: "50개씩 보기", value: "50"},
                {label: "100개씩 보기", value: "100"},
              ]}
              value={itemsPerPage.toString()}
              singleSelect={val => {
                setItemsPerPage(Number(val));
              }}
              placeholder="20개씩 보기"
              boxStyle={{width: "140px"}}
            />,
            <SelectBox
              key={5}
              multiple
              list={sortArr}
              boxStyle={{
                minWidth: "120px",
              }}
              // value={filter.sort}
              value={sorts}
              singleSelect={value =>
                setFilter({...filter, sort: value as TItemFilterSortType})
              }
              multipleSelect={(value = []) => {
                setSorts(value);
              }}
              placeholder="정렬"
            />,
            <SelectBox
              key={6}
              multiple
              list={[...partnerList].map(item => ({
                value: String(item.no),
                label: String(item.partner_name || ""),
              }))}
              boxStyle={{
                minWidth: "150px",
              }}
              // value={String(filter.partner)}
              value={partners}
              singleSelect={value => {
                setFilter({...filter, partner: Number(value)});
              }}
              multipleSelect={(value = []) => {
                // 순서대로 들어갈수 있게 기존 배열과 비교해서 새로 추가된 것만 추가
                setPartners(value);
              }}
              placeholder="업체선택"
            />,
            <InputBox
              key={2}
              value={searchText}
              // onValue={value => setFilter({...filter, searchtxt: value})}
              onValue={value => setSearchText(value)}
              onEnter={getList}
              rightContent={
                <div style={{display: "flex"}} onClick={() => getList()}>
                  <FontAwesomeIcon icon={faSearch} />
                </div>
              }
            />,
          ]}
          right={[
            <FileUploadButton
              key={1}
              text="엑셀 업로드"
              callback={() => getList()}
              type={"excel"}
              uploadUrl={"/admin/excel/prices"}
              // beforeUpload={async (event, ref) => {
              //   const files = ref.current?.files;
              //
              //   const {data, message} = await axiosUtil.fileUpload({
              //     files,
              //     endPoint: "/admin/excel/prices-preview",
              //   });
              //
              //   setAlertModal({
              //     isVisible: true,
              //     title: message,
              //   });
              //
              //   return Promise.resolve(false);
              // }}
            />,
            <Button
              key={0}
              fontSize={12}
              text="엑셀 다운로드"
              onClick={listExcelDownload}
              backgroundColor={styleUtils.COLOR.blueLight}
              icon={<FontAwesomeIcon icon={faDownload} />}
            />,
          ]}
        />
        <SearchFilter
          left={[
            <div key={1}>
              <InputBox
                value={String(percent)}
                onValue={value => {
                  const new_percent = Number(value.replace(/[^0-9]/gi, ""));
                  setPercent(new_percent);
                }}
                rightContent={
                  <Button
                    text="% 할인적용"
                    fontSize={12}
                    onClick={() => {
                      setListData({
                        ...listData,
                        result: (listData?.result || []).map(item => {
                          const checked =
                            selectNoList.filter(no => item.no === no).length >
                            0;
                          if (checked) {
                            return {
                              ...item,
                              new_promotion_price: Math.max(
                                Math.floor(
                                  (item.new_price || 0) *
                                    (100 - percent) *
                                    0.01 *
                                    0.1,
                                ) * 10,
                                0,
                              ),
                            };
                          } else {
                            return item;
                          }
                        }),
                      });
                    }}
                  />
                }
                rightContentStyle={{padding: 0}}
                inputStyle={{width: 40}}
              />
            </div>,
            <div key={2}>
              <Button
                text="저장"
                onClick={allSave}
                backgroundColor={COLOR.success}
              />
            </div>,
            <div key={3} style={{marginLeft: "4px"}}>
              <Button
                text="리셋"
                onClick={reset}
                backgroundColor={COLOR.warning}
              />
            </div>,
            <div key={4} style={{marginLeft: "4px"}}>
              <Button
                text="선택노출"
                onClick={changeViewYn}
                backgroundColor={COLOR.baige}
              />
            </div>,
            <div key={5} style={{marginLeft: "4px"}}>
              <Button
                text="쿠폰사용불가"
                onClick={() => changeCouponUseableYn({coupon_useable: "N"})}
                backgroundColor={COLOR.baige}
              />
            </div>,
            <div key={6} style={{marginLeft: "4px"}}>
              <Button
                text="쿠폰사용가능"
                onClick={() => changeCouponUseableYn({coupon_useable: "Y"})}
                backgroundColor={COLOR.baige}
              />
            </div>,
          ]}></SearchFilter>
        <Content>
          <div style={{flex: 1}}>
            <div className={styles.contentBtnDiv}></div>
            <div className={styles.gridContainer}>
              <ListTable paging={listData?.page} getList={getList}>
                <thead>
                  <tr>
                    <th>
                      <CheckBox
                        checked={isAllSelected}
                        text=""
                        onChange={() => {
                          if (isAllSelected) {
                            setSelectNoList([]);
                          } else {
                            setSelectNoList(
                              (listData?.result || [])
                                .map(data => data.no)
                                .filter(utils.isDefined),
                            );
                          }
                        }}
                      />
                    </th>
                    <th>번호</th>
                    <th>업체명</th>
                    <th>제품명</th>
                    <th>입고가격</th>
                    <th>정상가격</th>
                    <th>실구매가</th>
                    <th>정기구독</th>
                    <th>프로모션가</th>
                    <th>할인율</th>
                    <th>표기할인율</th>
                    <th>노출여부</th>
                    <th>쿠폰사용</th>
                  </tr>
                </thead>
                <tbody>
                  {(listData?.result || []).map((item, index) => {
                    const checked =
                      selectNoList.filter(no => item.no === no).length > 0;
                    const percent =
                      !!item.new_promotion_price &&
                      !isNaN(Number(item.new_promotion_price))
                        ? Math.ceil(
                            100 -
                              (Number(item.new_promotion_price) /
                                Number(item.new_price)) *
                                100,
                          )
                        : 0;
                    const app_percent =
                      !!item.new_promotion_price &&
                      !isNaN(Number(item.new_promotion_price))
                        ? Math.ceil(
                            100 -
                              (Number(item.new_promotion_price) /
                                Number(item.new_view_price)) *
                                100,
                          )
                        : !!item.new_price && !isNaN(Number(item.new_price))
                        ? Math.ceil(
                            100 -
                              (Number(item.new_price) /
                                Number(item.new_view_price)) *
                                100,
                          )
                        : 0;
                    const isShow = item.view_yn === "Y";
                    const isCouponUseAble = item.coupon_useable === "Y";

                    return (
                      <tr key={index}>
                        <td onClick={e => utils.handleClickBubble(e)}>
                          <CheckBox
                            checked={checked}
                            text=""
                            onChange={e => {
                              if (checked) {
                                setSelectNoList([
                                  ...selectNoList.filter(no => no !== item.no),
                                ]);
                              } else if (item.no) {
                                setSelectNoList([...selectNoList, item.no]);
                              }
                            }}
                          />
                        </td>
                        <td>{item.no}</td>
                        <td>{item.partners?.partner_name}</td>
                        <td>{item.item_name}</td>
                        <td>
                          <InputBox
                            value={String(item.new_input_price)}
                            inputStyle={{textAlign: "right", width: 60}}
                            onValue={value => {
                              const new_value =
                                utils.strReplaceJustNumber(value);
                              const new_input_price = Number(new_value);
                              setListData({
                                ...listData,
                                result: (listData?.result || []).map(
                                  (_item, _index) => {
                                    if (index === _index) {
                                      return {..._item, new_input_price};
                                    } else {
                                      return _item;
                                    }
                                  },
                                ),
                              });
                            }}
                          />
                        </td>
                        <td>
                          <InputBox
                            value={String(item.new_view_price)}
                            inputStyle={{textAlign: "right", width: 60}}
                            onValue={value => {
                              const new_value =
                                utils.strReplaceJustNumber(value);
                              const new_view_price = Number(new_value);
                              setListData({
                                ...listData,
                                result: (listData?.result || []).map(
                                  (_item, _index) => {
                                    if (index === _index) {
                                      return {..._item, new_view_price};
                                    } else {
                                      return _item;
                                    }
                                  },
                                ),
                              });
                            }}
                          />
                        </td>
                        <td>
                          <InputBox
                            value={String(item.new_price)}
                            inputStyle={{textAlign: "right", width: 60}}
                            onValue={value => {
                              const new_value =
                                utils.strReplaceJustNumber(value);
                              const new_price = Number(new_value);
                              setListData({
                                ...listData,
                                result: (listData?.result || []).map(
                                  (_item, _index) => {
                                    if (index === _index) {
                                      return {..._item, new_price};
                                    } else {
                                      return _item;
                                    }
                                  },
                                ),
                              });
                            }}
                          />
                        </td>
                        <td>
                          <InputBox
                            value={String(item.new_subscribe_price)}
                            inputStyle={{textAlign: "right", width: 60}}
                            onValue={value => {
                              const new_value =
                                utils.strReplaceJustNumber(value);
                              const new_subscribe_price = Number(new_value);
                              setListData({
                                ...listData,
                                result: (listData?.result || []).map(
                                  (_item, _index) => {
                                    if (index === _index) {
                                      return {..._item, new_subscribe_price};
                                    } else {
                                      return _item;
                                    }
                                  },
                                ),
                              });
                            }}
                          />
                        </td>
                        <td>
                          <InputBox
                            value={String(item.new_promotion_price || "")}
                            inputStyle={{textAlign: "right", width: 60}}
                            onValue={value => {
                              const new_value =
                                utils.strReplaceJustNumber(value);
                              const new_promotion_price =
                                !!new_value && !isNaN(Number(new_value))
                                  ? Number(new_value)
                                  : null;
                              setListData({
                                ...listData,
                                result: (listData?.result || []).map(
                                  (_item, _index) => {
                                    if (index === _index) {
                                      return {..._item, new_promotion_price};
                                    } else {
                                      return _item;
                                    }
                                  },
                                ),
                              });
                            }}
                          />
                        </td>
                        <td>{percent > 0 ? `${percent}%` : "-"}</td>
                        <td>{app_percent > 0 ? `${app_percent}%` : "-"}</td>
                        <td>
                          <span
                            className={[
                              styles.badge,
                              !isShow && styles.danger,
                            ].join(" ")}>
                            {isShow ? "노출" : "숨김"}
                          </span>
                        </td>
                        <td>
                          <span
                            className={[
                              styles.badge,
                              !isCouponUseAble && styles.danger,
                            ].join(" ")}>
                            {isCouponUseAble ? "가능" : "불가"}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </ListTable>
            </div>
          </div>
        </Content>
      </ContentLayout>
    </>
  );
}
