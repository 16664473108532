import React, {useEffect, useState } from "react";
import { Content, ContentLayout, ListTable } from "@components";
import { adminAPI } from "@utils";

export default function DynamicPricingPage() {
  const [promotions, setPromotions] = useState<any>([]);

  async function getList() {
    const result = await adminAPI.promotion.getPromotionList();
    return result;
  }

  useEffect(() => {
    getList().then(result => {
      setPromotions(result.data);
    });
  }, [])

  return (
    <>
      <ContentLayout title="가격변동설정">
        {/*<SearchFilter/>*/}
        <Content>
          <ListTable>
            <colgroup>
              {/*<col width="10%" />*/}
              {/*<col width="20%" />*/}
              {/*<col width="20%" />*/}
              {/*<col width="20%" />*/}
              {/*<col width="20%" />*/}
              {/*<col width="10%" />*/}
            </colgroup>
            <thead>
              <tr>
                <th>상품명</th>
                <th>시작일</th>
                <th>종료일</th>
                <th>현재 가격</th>
                <th>변동가격(원복가격)</th>
                <th>한정 수량</th>
                <th>만료 여부</th>
              </tr>
            </thead>
            <tbody>
              {promotions.map((promotion: any) => (
                <tr key={promotion.no}>
                  <td>{promotion.item_name}</td>
                  <td>{promotion.start_date}</td>
                  <td>{promotion.end_date}</td>
                  <td>{
                    (promotion.view_promotion_price) ?
                    (promotion.view_promotion_price) :
                        (promotion.view_price)
                  }</td>
                  <td>
                    {promotion.promotion_price}
                    {promotion.expired_yn === "Y" &&
                      `(${promotion.default_price})`}
                  </td>
                  <td>{promotion.limit_count}</td>
                  <td>{promotion.expired_yn}</td>
                </tr>
              ))}
            </tbody>
          </ListTable>
        </Content>
      </ContentLayout>
    </>
  );
}
