import React from "react";
import styles from "./style.module.scss";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface IProps {
  title?: string;
  children: JSX.Element | JSX.Element[];
  className?: string;
  buttons?: JSX.Element | JSX.Element[];
}

export default function ContentLayout({
  title,
  children,
  className = "",
  buttons,
}: IProps) {
  return (
    <div className={[styles.container, className].join(" ")}>
      <header>
        <h1>{title}</h1>
        <div className={styles.buttonWrapper}>{buttons}</div>
      </header>
      <div>
        <div className={styles.contentWrapper}>{children}</div>
      </div>
    </div>
  );
}
