import React from "react";
import styles from "./style.module.scss";

interface IProps {
  left?: React.ReactNode[];
  right?: React.ReactNode[];
  search?: React.ReactNode;
  bottom?: React.ReactNode;
}

const SearchFilter: React.FC<IProps> = ({left, right, search, bottom}) => {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.leftSearchCol}>
          <div className={styles.leftFilterCol}>
            {left?.length ? (
              <>
                {left.map((ele, index) => (
                  <div key={index}>{ele}</div>
                ))}
              </>
            ) : null}
          </div>
          <>
            {search ? (
              <div className={styles.leftFilterCol}>
                <>
                  <div>{search}</div>
                </>
              </div>
            ) : null}
          </>
        </div>
        {right?.length ? (
          <div className={styles.rightCol}>
            {right.map((ele, index) => (
              <div key={index} className={styles.col}>
                {ele}
              </div>
            ))}
          </div>
        ) : null}
      </div>
      {bottom && <div className={styles.bottom}>{bottom}</div>}
    </div>
  );
};

export default SearchFilter;
