import * as data from "@data";
import {TStrBoolean} from "@data";
import {axiosGetUtil, axiosPostUtil} from "./axiosUtil";

const adminAPI = {
  admin: {
    apiAdminLogin: async (params: {id: string; pw: string}) => {
      return await axiosPostUtil<
        data.ApiResponse<{
          token: {
            accessToken: string;
            refreshToken: string;
          };
        }>
      >("/admin/login", params).then(value => value?.data);
    },

    apiGetAdminData: async () => {
      return await axiosPostUtil<
        data.ApiResponse<{
          result: data.IDBAdmin;
          token: {
            accessToken: string;
            refreshToken: string;
          };
        }>
      >("/admin/getAdmin").then(value => value?.data);
    },

    apiGetCommon: async () => {
      return await axiosPostUtil<data.ApiResponse<data.ICommonResponse>>(
        "/admin/getCommon",
      ).then(value => value?.data);
    },
  },

  banner: {
    getList: async (params: {
      limit?: number;
      page: number;
      searchtxt?: string;
      filters?: string[];
      sort?: string;
      direction?: string;
    }) => {
      await console.log(params);
      return await axiosPostUtil<
        data.ApiResponse<data.ResultPagingResponse<data.IBannerResponse[]>>
      >("/admin/banner/getList", params).then(value => value?.data);
    },

    getBanner: async (params: {no: number}) => {
      return await axiosPostUtil<data.ApiResponse<data.IBanner>>(
        "/admin/banner/getBanner",
        params,
      ).then(value => value?.data);
    },

    getDetail: async (params: {no: number}) => {
      return await axiosPostUtil<data.ApiResponse<{result: data.IBanner}>>(
        "/admin/banner/getDetail",
        params,
      ).then(value => value?.data);
    },

    updateBanner: async (params: data.IBannerRequest) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/banner/updateBanner",
        params,
      ).then(value => value?.data);
    },

    createBanner: async (params: data.IBannerRequest) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/banner/createBanner",
        params,
      ).then(value => value?.data);
    },

    deleteBanner: async (params: {no: number}) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/banner/deleteBanner",
        params,
      ).then(value => value?.data);
    },

    updateDetailBanner: async (params: {
      no: number;
      items: number[];
      details: data.IBannerDetail[];
      items_title: string;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/banner/updateDetailBanner",
        params,
      ).then(value => value?.data);
    },

    changeOrdering: async (params: {no: number; type: data.TUpDown}) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/banner/changeOrdering",
        params,
      ).then(value => value?.data);
    },
  },

  coupon: {
    getList: async (params: {
      limit?: number;
      page?: number;
      searchtxt: string;
      useable_yn?: data.TypeYNWithEmpty;
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<data.ResultPagingResponse<data.ICouponList[]>>
      >("/admin/coupon/getList", params).then(value => value?.data);
    },

    getDetail: async (params: {no: number}) => {
      return await axiosPostUtil<data.ApiResponse<data.IDBCouponList>>(
        "/admin/coupon/getDetail",
        params,
      ).then(value => value?.data);
    },

    createCoupon: async (params: data.ICouponListRequest) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/coupon/createCoupon",
        params,
      ).then(value => value?.data);
    },

    updateCoupon: async (params: data.ICouponListRequest) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/coupon/updateCoupon",
        params,
      ).then(value => value?.data);
    },

    deleteCoupon: async (params: {no: number}) => {
      return await axiosPostUtil<data.ApiResponse<data.IDBCouponList[]>>(
        "/admin/coupon/deleteCoupon",
        params,
      ).then(value => value?.data);
    },

    apiGetAllCouponList: async () => {
      return await axiosPostUtil<data.ApiResponse<data.IDBCouponList[]>>(
        "/admin/coupon/getCouponList",
      ).then(value => value?.data);
    },

    getCouponCodeList: async (params: {
      coupon_no: number;
      page: number;
      limit?: number;
      searchtxt?: string;
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<data.ResultPagingResponse<data.IDBCouponCodeList[]>>
      >("/admin/coupon/getCouponCodeList", params).then(value => value?.data);
    },

    createCouponCode: async (params: {
      coupon_no: number;
      coupon_code: string;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/coupon/createCouponCode",
        params,
      ).then(value => value?.data);
    },

    getIssuedMemberList: async (params: {
      coupon_no: number;
      page: number;
      limit?: number;
      searchtxt?: string;
      use_type?: "Y" | "N" | "expired" | "";
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<data.ResultPagingResponse<data.IIssuedCoupon[]>>
      >("/admin/coupon/getIssuedMemberList", params).then(value => value?.data);
    },

    sendCoupon: async (params: {
      member: number[];
      coupon_no: number;
      use_start_date: string;
      use_end_date: string;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/coupon/sendCoupon",
        params,
      ).then(value => value?.data);
    },

    deleteUnUsedCoupon: async (params: {coupon_no: number}) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/coupon/deleteUnUsedCoupon",
        params,
      ).then(value => value?.data);
    },

    confirmCreateCoupon: async (params: {
      coupon_no: number;
      filePath: string;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/coupon/confirmCreateCoupon",
        params,
      ).then(value => value?.data);
    },
  },

  dashboard: {
    getData: async (params: {date: string}) => {
      return await axiosPostUtil<data.ApiResponse<data.IDashBoardResponse>>(
        "/admin/dashboard/getData",
        params,
      ).then(value => value?.data);
    },

    getReviewData: async (params: {
      start_date: string;
      end_date: string;
      itemList: number[];
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<data.IDashBoardReviewResponse[]>
      >("/admin/dashboard/getReviewData", params).then(value => value?.data);
    },

    getSignupData: async (params: {start_date: string; end_date: string}) => {
      return await axiosPostUtil<
        data.ApiResponse<data.IDashBoardSignupResponse[]>
      >("/admin/dashboard/getSignupData", params).then(value => value?.data);
    },

    getOrderData: async (params: {start_date: string; end_date: string}) => {
      return await axiosPostUtil<
        data.ApiResponse<data.IDashBoardOrderResponse[]>
      >("/admin/dashboard/getOrderData", params).then(value => value?.data);
    },
  },

  deliveryDate: {
    getList: async (params: {
      year?: number;
      month?: number;
      start_date?: string;
    }) => {
      return await axiosPostUtil<data.ApiResponse<data.IDBDeliveryDate[]>>(
        "/admin/deliveryDate/getList",
        params,
      ).then(value => value?.data);
    },

    getDetail: async (params: {date: string}) => {
      return await axiosPostUtil<data.ApiResponse<data.IDeliveryDateResponse>>(
        "/admin/deliveryDate/getDetail",
        params,
      ).then(value => value?.data);
    },

    createDate: async (params: {
      delivery_datetime: string;
      milk_yn: data.TypeYN;
      recommandItemList?: {title: string; item_no: number}[];
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/deliveryDate/createDate",
        params,
      ).then(value => value?.data);
    },

    updateDate: async (params: {
      no: number;
      delivery_datetime: string;
      milk_yn: data.TypeYN;
      recommandItemList?: {no?: number; title: string; item_no: number}[];
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/deliveryDate/updateDate",
        params,
      ).then(value => value?.data);
    },

    deleteDate: async (params: {no: number}) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/deliveryDate/deleteDate",
        params,
      ).then(value => value?.data);
    },
  },

  event: {
    getEventPopupList: async (params: {
      limit?: number;
      page: number;
      searchtxt?: string;
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<data.ResultPagingResponse<data.IDBEventPopup[]>>
      >("/admin/event/getEventPopupList", params).then(value => value?.data);
    },

    getEventPopupDetail: async (params: {no: number}) => {
      return await axiosPostUtil<data.ApiResponse<data.IDBEventPopup>>(
        "/admin/event/getEventPopupDetail",
        params,
      ).then(value => value?.data);
    },

    updateEventPopup: async (params: data.IEventPopupRequest) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/event/updateEventPopup",
        params,
      ).then(value => value?.data);
    },

    createEventPopup: async (params: data.IEventPopupRequest) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/event/createEventPopup",
        params,
      ).then(value => value?.data);
    },

    deleteEventPopup: async (params: {no: number}) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/event/deleteEventPopup",
        params,
      ).then(value => value?.data);
    },

    changeEventPopupOrdering: async (params: {
      no: number;
      type: "up" | "down";
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/event/changeEventPopupOrdering",
        params,
      ).then(value => value?.data);
    },

    getEventAttendanceList: async (params: {
      page: number;
      limit?: number;
      searchtxt?: string;
      successYn?: data.TypeYNWithEmpty;
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<data.ResultPagingResponse<data.IEventAttendanceList[]>>
      >("/admin/event/getEventAttendanceList", params).then(
        value => value?.data,
      );
    },

    getFrequencyList: async (params: {
      page: number;
      limit?: number;
      searchtxt?: string;
      successYn?: data.TypeYNWithEmpty;
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<data.ResultPagingResponse<data.IFrequencyList[]>>
      >("/admin/event/getFrequencyList", params).then(value => value?.data);
    },

    setDeliveryConfirm: async (params: {
      no: number;
      frequency_no: number;
      step_no: number;
      member_no: number;
      confirm_yn: data.TypeYN;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/event/setDeliveryConfirm",
        params,
      ).then(value => value?.data);
    },
  },

  faq: {
    apiGetFaqList: async (params: {
      limit?: number;
      page: number;
      searchtxt?: string;
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<data.ResultPagingResponse<data.IFaq[]>>
      >("/admin/faq/getList", params).then(value => value?.data);
    },

    apiGetFaqDetail: async (params: {no: number}) => {
      return await axiosPostUtil<data.ApiResponse<data.IDBFaq>>(
        "/admin/faq/getDetail",
        params,
      ).then(value => value?.data);
    },

    apiCreateFaq: async (params: data.IDBFaq) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/faq/createFaq",
        params,
      ).then(value => value?.data);
    },

    apiUpdateFaq: async (params: data.IDBFaq) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/faq/updateFaq",
        params,
      ).then(value => value?.data);
    },

    apiDeleteFaq: async (params: data.IDBFaq) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/faq/deleteFaq",
        params,
      ).then(value => value?.data);
    },

    apiToggleHideYNFaq: async (params: {no: number; hide_yn: data.TypeYN}) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/faq/toggleHideView",
        params,
      ).then(value => value?.data);
    },

    apiToggleTopYNFaq: async (params: {no: number; top_yn: data.TypeYN}) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/faq/toggleTop",
        params,
      ).then(value => value?.data);
    },

    apiGetFaqGroup: async () => {
      return await axiosPostUtil<
        data.ApiResponse<{result: data.IDBFaqGroup[]}>
      >("/admin/faq/getAllFaqGroup").then(value => value?.data);
    },
  },

  homeTab: {
    apiGetHomeTabList: async (params: {
      limit?: number;
      page: number;
      searchtxt?: string;
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<data.ResultPagingResponse<data.IDBHomeTabList[]>>
      >("/admin/homeTab/getList", params).then(value => value?.data);
    },

    apiGetHomeTabDetail: async (params: {no: number}) => {
      return await axiosPostUtil<data.ApiResponse<data.IDBHomeTabList>>(
        "/admin/homeTab/getDetail",
        params,
      ).then(value => value?.data);
    },

    apiCreateHomeTab: async (params: data.IDBHomeTabList) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/homeTab/createHomeTab",
        params,
      ).then(value => value?.data);
    },

    apiUpdateHomeTab: async (params: data.IDBHomeTabList) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/homeTab/updateHomeTab",
        params,
      ).then(value => value?.data);
    },

    apiDeleteHomeTab: async (params: {no: number}) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/homeTab/deleteHomeTab",
        params,
      ).then(value => value?.data);
    },

    getHomeTabList: async () => {
      return await axiosPostUtil<data.ApiResponse<data.IDBHomeTabList[]>>(
        "/admin/homeTab/getHomeTabList",
      ).then(value => value?.data);
    },
  },

  item: {
    getList: async (params: {
      partner?: number;
      searchtxt?: string;
      limit?: number;
      page?: number;
      sort?: string;
      partners?: number[];
      sorts?: string[];
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<data.ResultPagingResponse<data.IItemList[]>>
      >("/admin/item/getList", params).then(value => value?.data);
    },

    getPriceListExportExcel: async (params: {
      partner?: number;
      searchtxt?: string;
      limit?: number;
      page?: number;
      sort?: string;
      partners?: number[];
      sorts?: string[];
    }) => {
      return await axiosPostUtil<data.ApiResponse<{result: string}>>(
        "/admin/item/getPriceListExportExcel",
        params,
      ).then(value => value?.data);
    },

    exportExcelBarcodeList: async (params: {
      partner?: number;
      searchtxt?: string;
      limit?: number;
      page?: number;
      sort?: string;
    }) => {
      return await axiosPostUtil<data.ApiResponse<{result: string}>>(
        "/admin/item/exportExcelBarcodeList",
        params,
      ).then(value => value?.data);
    },

    toggleViewYn: async (params: {no: number; view_yn: data.TypeYN}) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/item/toggleViewYn",
        params,
      ).then(value => value?.data);
    },

    toggleViewYnList: async (params: {
      item_list: number[];
      view_yn: data.TypeYN;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/item/toggleViewYnList",
        params,
      ).then(value => value?.data);
    },

    toggleCouponUseableYnList: async (params: {
      item_list: number[];
      coupon_useable: data.TypeYN;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/item/toggleCouponUseableYnList",
        params,
      ).then(value => value?.data);
    },

    getDetail: async (params: {no: number}) => {
      return await axiosPostUtil<data.ApiResponse<data.IItemDetail>>(
        "/admin/item/getDetail",
        params,
      ).then(value => value?.data);
    },

    apiGetAllItems: async (params: {partner_no?: number}) => {
      return await axiosPostUtil<
        data.ApiResponse<{
          result: data.IDBProduct[];
        }>
      >("/admin/item/getAllItems", params).then(value => value?.data);
    },

    getItemWithStock: async (params: {
      partner_no?: number;
      view_yn?: data.TypeYN;
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<{
          result: data.IProductWithStock[];
        }>
      >("/admin/item/getItemWithStock", params).then(value => value?.data);
    },

    getPetKindList: async () => {
      return await axiosPostUtil<data.ApiResponse<data.IDBPetKind[]>>(
        "/admin/item/getPetKindList",
      ).then(value => value?.data);
    },

    apiGetItemQnAList: async (params: {
      item_no?: number;
      limit?: number;
      page: number;
      searchtxt?: string;
      answer_yn?: data.TypeYNWithEmpty;
      member_no?: number;
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<
          data.ResultPagingResponse<{
            list?: data.IItemQnaListResponse[];
            count?: {allCnt?: number; notCnt?: number};
          }>
        >
      >("/admin/item/getQnAList", params).then(value => value?.data);
    },

    apiGetItemQnADetail: async (params: {no: number}) => {
      return await axiosPostUtil<data.ApiResponse<data.IItemQnaDetailResponse>>(
        "/admin/item/getQnaDetail",
        params,
      ).then(value => value?.data);
    },

    apiUpdateItemQnaAnswer: async (params: {
      no: number;
      answer: string;
      admin_no: number;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/item/updateQnaAnswer",
        params,
      ).then(value => value?.data);
    },

    getReviewList: async (params: {
      member_no?: number;
      partner_no?: number;
      item_no?: number;
      searchtxt?: string;
      limit?: number;
      page?: number;
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<
          data.ResultPagingResponse<{
            list?: data.IReviewListResponse[];
            allCnt?: number;
          }>
        >
      >("/admin/item/getReviewList", params).then(value => value?.data);
    },

    apiGetReviewDetail: async (params: {no: number}) => {
      return await axiosPostUtil<data.ApiResponse<data.IReviewDetailResponse>>(
        "/admin/item/getReviewDetail",
        params,
      ).then(value => value?.data);
    },

    apiUpdateRreviewBestYn: async (params: {
      no: number;
      item_no: number;
      best_yn: data.TypeYN;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/item/toggleReviewBestYn",
        params,
      ).then(value => value?.data);
    },

    getItemCategory: async (params: {type_no?: number}) => {
      return await axiosPostUtil<
        data.ApiResponse<{result: data.IDBItemCategory[]}>
      >("/admin/item/getItemCategory", params).then(value => value?.data);
    },

    getItemTypes: async () => {
      return await axiosPostUtil<
        data.ApiResponse<{result: data.IDBItemType[]}>
      >("/admin/item/getItemTypes").then(value => value?.data);
    },

    getBadgeList: async () => {
      return await axiosPostUtil<
        data.ApiResponse<{result: data.IDBBadgeList[]}>
      >("/admin/item/getBadgeList").then(value => value?.data);
    },

    checkItemName: async (params: {
      no?: number;
      item_partner_no: number;
      item_name: string;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/item/checkItemName",
        params,
      ).then(value => value?.data);
    },

    createItem: async (params: data.IProductRequest) => {
      return await axiosPostUtil<data.ApiResponse<{result: data.IDBProduct}>>(
        "/admin/item/createItem",
        params,
      ).then(value => value?.data);
    },

    updateItem: async (params: data.IProductRequest) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/item/updateItem",
        params,
      ).then(value => value?.data);
    },

    getItemMaterialList: async (params: {item_no: number}) => {
      return await axiosPostUtil<
        data.ApiResponse<data.ResultPagingResponse<data.IMaterialList[]>>
      >("/admin/item/getItemMaterialList", params).then(value => value?.data);
    },

    toggleMaterialYn: async (params: {
      no: number;
      item_no: number;
      main_yn?: data.TypeYN;
      organic_yn?: data.TypeYN;
      antibiotic_free_yn?: data.TypeYN;
      natural_yn?: data.TypeYN;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/item/toggleMaterialYn",
        params,
      ).then(value => value?.data);
    },

    changeMaterialOrdering: async (params: {
      no: number;
      item_no: number;
      type: data.TUpDown;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/item/changeMaterialOrdering",
        params,
      ).then(value => value?.data);
    },

    deleteItemMaterial: async (params: {no: number; item_no: number}) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/item/deleteItemMaterial",
        params,
      ).then(value => value?.data);
    },

    getMaterialList: async () => {
      return await axiosPostUtil<
        data.ApiResponse<data.ResultPagingResponse<data.IDBMaterialList[]>>
      >("/admin/item/getMaterialList").then(value => value?.data);
    },

    createItemMaterial: async (params: {
      item_no: number;
      material_no?: number;
      material_text: string;
      out_title: string;
      domestic_yn: data.TypeYN;
      from_country?: string;
      main_yn: data.TypeYN;
      content_percent: number;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/item/createItemMaterial",
        params,
      ).then(value => value?.data);
    },

    updateItemMaterialContentPercentMulti: async (params: {
      list: {no: number; item_no: number; content_percent: number}[];
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/item/updateItemMaterialContentPercentMulti",
        params,
      ).then(value => value?.data);
    },

    getItemStockList: async (params: {
      item_no: number;
      limit?: number;
      page: number;
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<data.ResultPagingResponse<data.IStockList[]>>
      >("/admin/item/getItemStockList", params).then(value => value?.data);
    },

    getItemStockDateList: async (params: {
      item_no: number;
      limit?: number;
      page: number;
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<data.ResultPagingResponse<data.IDBItemStockDate[]>>
      >("/admin/item/getItemStockDateList", params).then(value => value?.data);
    },

    updateStockMemo: async (params: {item_no: number; stock_memo: string}) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/item/updateStockMemo",
        params,
      ).then(value => value?.data);
    },

    createItemStock: async (params: {
      item_no: number;
      type: data.TInOut;
      count: number;
      insert_date?: string;
      limit_date?: string;
      made_date?: string;
      admin_name?: string;
      memo?: string;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/item/createItemStock",
        params,
      ).then(value => value?.data);
    },

    updateItemStock: async (params: {
      no: number;
      item_no: number;
      type: data.TInOut;
      count: number;
      insert_date?: string;
      limit_date?: string;
      made_date?: string;
      admin_name?: string;
      memo?: string;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/item/updateItemStock",
        params,
      ).then(value => value?.data);
    },

    deleteItemStock: async (params: {no: number; item_no: number}) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/item/deleteItemStock",
        params,
      ).then(value => value?.data);
    },

    createItemStockDate: async (params: {
      item_no: number;
      stock_date: string;
      limit_date?: string;
      made_date?: string;
      stock_count: number;
      admin_name?: string;
      memo?: string;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/item/createItemStockDate",
        params,
      ).then(value => value?.data);
    },

    updateItemStockDate: async (params: {
      no: number;
      item_no: number;
      stock_date: string;
      limit_date?: string;
      made_date?: string;
      stock_count: number;
      admin_name?: string;
      memo?: string;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/item/updateItemStockDate",
        params,
      ).then(value => value?.data);
    },

    deleteItemStockDate: async (params: {no: number; item_no: number}) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/item/deleteItemStockDate",
        params,
      ).then(value => value?.data);
    },

    getItemOptionList: async (params: {item_no: number}) => {
      return await axiosPostUtil<data.ApiResponse<data.IDBItemsOption[]>>(
        "/admin/item/getItemOptionList",
        params,
      ).then(value => value?.data);
    },

    getDetailList: async (params: {
      item_no: number;
      limit?: number;
      page?: number;
    }) => {
      return await axiosPostUtil<data.ApiResponse<data.TProductItemDetail[]>>(
        "/admin/item/getDetailList",
        params,
      ).then(value => value?.data);
    },

    toggleHideItemDetail: async (params: {
      no: number;
      item_no: number;
      hide_yn: data.TypeYN;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/item/toggleHideItemDetail",
        params,
      ).then(value => value?.data);
    },

    getItemDetail: async (params: {no: number; item_no: number}) => {
      return await axiosPostUtil<data.ApiResponse<data.TProductItemDetail>>(
        "/admin/item/getItemDetail",
        params,
      ).then(value => value?.data);
    },

    createItemDetail: async (params: {
      item_no: number;
      data_json: data.TProductItemDetailDataJson;
      type: data.TProductDetailType;
      pet_kind_list?: number[];
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/item/createItemDetail",
        params,
      ).then(value => value?.data);
    },

    updateItemDetail: async (params: {
      no: number;
      item_no: number;
      data_json: data.TProductItemDetailDataJson;
      type: data.TProductDetailType;
      pet_kind_list?: number[];
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/item/updateItemDetail",
        params,
      ).then(value => value?.data);
    },

    createItemOption: async (params: {
      item_no: number;
      option_name: string;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/item/createItemOption",
        params,
      ).then(value => value?.data);
    },

    deleteItemOption: async (params: {item_no: number; no: number}) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/item/deleteItemOption",
        params,
      ).then(value => value?.data);
    },

    cloneItem: async (params: {item_no: number}) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/item/cloneItem",
        params,
      ).then(value => value?.data);
    },

    changeProductPrice: async (params: {data: data.IEditItemPrice[]}) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/item/changeProductPrice",
        params,
      ).then(value => value?.data);
    },

    copyItemDetails: async (params: {
      item_no_list: number[];
      detail_list: data.ISelectedDetailList[];
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/item/copyItemDetails",
        params,
      ).then(value => value?.data);
    },

    copyItemMaterial: async (params: {
      item_no_list: number[];
      material_no_list: number[];
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/item/copyItemMaterial",
        params,
      ).then(value => value?.data);
    },

    getDmbList: async (params: {
      type_no?: number;
      default_yn?: data.TypeYNWithEmpty;
    }) => {
      return await axiosPostUtil<data.ApiResponse<data.IDBDmbList[]>>(
        "/admin/item/getDmbList",
        params,
      ).then(value => value?.data);
    },

    getItemDmbTitleList: async (params: {item_no: number}) => {
      return await axiosPostUtil<data.ApiResponse<data.IDBItemDmbTitle[]>>(
        "/admin/item/getItemDmbTitleList",
        params,
      ).then(value => value?.data);
    },

    getItemDmbList: async (params: {item_no: number; type_no?: number}) => {
      return await axiosPostUtil<data.ApiResponse<data.IItemDmbList[]>>(
        "/admin/item/getItemDmbList",
        params,
      ).then(value => value?.data);
    },

    copyItemDmb: async (params: {
      item_no_list: number[];
      item_dmb_no_list: number[];
      type_no?: number;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/item/copyItemDmb",
        params,
      ).then(value => value?.data);
    },

    copyItemDmbTitle: async (params: {
      type_no: number;
      item_no: number;
      item_no_list: number[];
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/item/copyItemDmbTitle",
        params,
      ).then(value => value?.data);
    },

    saveItemDmb: async (params: data.ISaveItemDmbList) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/item/saveItemDmb",
        params,
      ).then(value => value?.data);
    },

    saveItemDmbTitle: async (params: {
      item_no: number;
      item_type_no: number;
      title: string;
      content: string;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/item/saveItemDmbTitle",
        params,
      ).then(value => value?.data);
    },

    getSubscribeMemberList: async (params: {
      item_no: number;
      page: number;
      limit?: number;
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<
          data.ResultPagingResponse<data.IProductSubscribeMemberList[]>
        >
      >("/admin/item/getSubscribeMemberList", params).then(
        value => value?.data,
      );
    },

    getStockExcelTeamFresh: async (params: {
      searchDate: string;
      page: number;
      limit?: number;
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<data.ResultPagingResponse<data.ITeamfreshStockExcel[]>>
      >("/admin/item/getStockExcelTeamFresh", params).then(
        value => value?.data,
      );
    },

    createStockExcelTeamFresh: async (params: {
      list: data.IEditTeamfreshStockExcelRequest[];
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/item/createStockExcelTeamFresh",
        params,
      ).then(value => value?.data);
    },

    deleteReview: async (params: {no: number}) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/item/deleteReview",
        params,
      ).then(value => value?.data);
    },
  },

  kpi: {
    apiGetKpiData: async (params: {year: string}) => {
      return await axiosPostUtil<data.ApiResponse<data.IKPIResponse>>(
        "/admin/kpi/getKPI",
        params,
      ).then(value => value?.data);
    },

    getOrderSettlementList: async (params: {
      start_date: string;
      end_date: string;
      order_number?: string;
      page: number;
      limit?: number;
      isMinus?: boolean;
      point?: number;
      pointSelect?: data.TFilterSelectUpDown;
      profit?: number;
      profitSelect?: data.TFilterSelectUpDown;
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<
          data.ResultPagingResponse<data.IOrderSettlementResponse[]>
        >
      >("/admin/kpi/getOrderSettlementList", params).then(value => value?.data);
    },

    getOrderSettlementSummary: async (params: {
      start_date: string;
      end_date: string;
      point?: number;
      pointSelect?: data.TFilterSelectUpDown;
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<data.IOrderSettlementResponse>
      >("/admin/kpi/getOrderSettlementSummary", params).then(
        value => value?.data,
      );
    },

    excelFormDownload: async () => {
      return await axiosPostUtil<data.ApiResponse<{result: string}>>(
        "/admin/kpi/excelFormDownload",
      ).then(value => value?.data);
    },

    getOrderSettlement: async (params: {year: string; month: string}) => {
      return await axiosPostUtil<data.ApiResponse<data.IDBOrderSettlement>>(
        "/admin/kpi/getOrderSettlement",
        params,
      ).then(value => value?.data);
    },

    saveOrderSettlement: async (params: {
      year: string;
      month: string;
      storage_fee: number;
    }) => {
      return await axiosPostUtil<data.ApiResponse<data.IDBOrderSettlement>>(
        "/admin/kpi/saveOrderSettlement",
        params,
      ).then(value => value?.data);
    },

    getCohortMonthly: async (params?: {
      startMonth?: string;
      endMonth?: string;
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<data.ICohortMonthlyResponse[]>
      >("/admin/kpi/getCohortMonthly", params).then(value => value?.data);
    },
  },

  marketing: {
    apiGetMarketingLog: async (params: {
      limit: number;
      page: number;
      searchtxt?: string;
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<data.ResultPagingResponse<data.IDBMarketingLog[]>>
      >("/admin/marketing/getList", params).then(value => value?.data);
    },

    apiDeleteMarketingLog: async ({no}: {no: number}) => {
      return await axiosPostUtil<data.ApiResponse>("/admin/marketing/delLog", {
        no,
      }).then(value => value?.data);
    },

    apiGetMarketingLogDetail: async ({no}: {no: number}) => {
      return await axiosPostUtil<
        data.ApiResponse<data.IMarketingLogDetailResponse>
      >("/admin/marketing/getDetail", {no}).then(value => value?.data);
    },

    apiGetMarketingLogMemberList: async (params: {
      no: number;
      page: number;
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<{
          result: data.ResultPagingResponse<
            data.IMarketingLogMemberListResponse[]
          >;
        }>
      >("/admin/marketing/getClickMemberList", params).then(
        value => value?.data,
      );
    },

    apiGetPrevPushSendCount: async (params: data.IMarketingLogFilter) => {
      return await axiosPostUtil<
        data.ApiResponse<{result: data.IMarketingPrevCount}>
      >("/admin/marketing/previewPushSnedCount", params).then(
        value => value?.data,
      );
    },

    apiExportExcelMarketingMemberList: async (
      params: data.IMarketingLogFilter,
    ) => {
      return await axiosPostUtil<data.ApiResponse<{result: string}>>(
        "/admin/marketing/exportExcel",
        params,
      ).then(value => value?.data);
    },

    getContentNoList: async (params: {
      type: string;
      hide_yn?: data.TypeYNWithEmpty;
    }) => {

      //params에서 hide_yn삭제
      delete params.hide_yn;

      return await axiosPostUtil<
        data.ApiResponse<{result: data.IDeeplinkContentNoListResponse[]}>
      >("/admin/marketing/getContentNoList", params).then(value => value?.data);
    },

    getContentAllList: async (params: {
      type: string;
    }) => {
      return await axiosPostUtil<
          data.ApiResponse<{result: data.IDeeplinkContentNoListResponse[]}>
      >("/admin/marketing/getContentNoList", params).then(value => value?.data);
    },

    apiReservationPushNotification: async (
      params: data.IMarketingSaveRequest,
    ) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/marketing/reservationMarketing",
        params,
      ).then(value => value?.data);
    },

    apiSendPushNotification: async (params: data.IMarketingSaveRequest) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/marketing/createMarketing",
        params,
      ).then(value => value?.data);
    },

    getExportMarketingList: async (params: {searchtxt?: string}) => {
      return await axiosPostUtil<data.ApiResponse<{result: string}>>(
        "/admin/marketing/getExportMarketingList",
        params,
      ).then(value => value?.data);
    },
  },

  member: {
    getList: async ({
      limit = 15,
      page = 1,
      sort = "orderCntDESC",
      ...rest
    }: {
      limit?: number;
      page?: number;
      searchtxt?: string;
      sort?: data.TMemberOrderSort;
      grade?: string;
      member_type?: data.TMemberType;
      subscribe_y?: boolean;
      gift_apply_y?: boolean;
      supporters_y?: boolean;
      searchCreatedStartAt?: string;
      searchCreatedEndAt?: string;
      organic?: string;
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<
          data.ResultPagingResponse<{
            list: data.IMemberListResponse[];
            memberCnt?: number;
            memberOutCnt?: number;
            purchaseMemberCnt?: number;
            todayJoinCnt?: number;
          }>
        >
      >("/admin/member/getList", {limit, page, sort, ...rest}).then(
        value => value?.data,
      );
    },

    getExportMemberList: async ({
      limit = 15,
      page = 1,
      sort = "orderCntDESC",
      ...rest
    }: {
      limit?: number;
      page?: number;
      searchtxt?: string;
      sort?: data.TMemberOrderSort;
      grade?: string;
      member_type?: data.TMemberType;
      subscribe_y?: boolean;
      gift_apply_y?: boolean;
      supporters_y?: boolean;
      searchCreatedStartAt?: string;
      searchCreatedEndAt?: string;
    }) => {
      return await axiosPostUtil<data.ApiResponse<{result: string}>>(
        "/admin/member/getExportMemberList",
        {limit, page, sort, ...rest},
      ).then(value => value?.data);
    },

    getDetail: async (params: {member_no: number}) => {
      return await axiosPostUtil<
        data.ApiResponse<{result: data.IMemberResponse}>
      >("/admin/member/getDetail", params).then(value => value?.data);
    },

    getCouponList: async (params: {member_no: number; page: number}) => {
      return await axiosPostUtil<
        data.ApiResponse<data.ResultPagingResponse<data.ICoupon[]>>
      >("/admin/member/getCouponList", params).then(value => value?.data);
    },

    updateMember: async ({no: member_no, ...rest}: data.IDBMember) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/member/updateMember",
        {
          member_no,
          ...rest,
        },
      ).then(value => value?.data);
    },

    addPointSaveMulti: async (params: {
      member_arr: number[];
      point: number;
      title: string;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/member/addPointSaveMulti",
        params,
      ).then(value => value?.data);
    },

    apiGetMemberQnAList: async (params: {
      limit?: number;
      page: number;
      searchtxt?: string;
      answer_yn?: data.TypeYNWithEmpty;
      member_no?: number;
      group_no?: number;
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<
          data.ResultPagingResponse<{
            list?: data.IMemberQnaListResponse[];
            count?: {allCnt?: number; notCnt?: number};
          }>
        >
      >("/admin/member/getQnAList", params).then(value => value?.data);
    },

    apiGetMemberQnADetail: async (params: {no: number}) => {
      return await axiosPostUtil<data.ApiResponse<data.IMemberQnaListResponse>>(
        "/admin/member/getQnaDetail",
        params,
      ).then(value => value?.data);
    },

    apiUpdateMemberQnaAnswer: async (params: {
      no: number;
      answer: string;
      admin_no: number;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/member/updateQnaAnswer",
        params,
      ).then(value => value?.data);
    },

    getMemberOrderList: async (params: {
      member_no: number;
      page: number;
      limit?: number;
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<
          data.ResultPagingResponse<{
            list?: data.IOrderList[];
            count?: {orderCnt?: number; cancelCnt?: number};
          }>
        >
      >("/admin/member/getMemberOrderList", params).then(value => value?.data);
    },

    getMemberPointList: async (params: {
      member_no: number;
      page: number;
      limit?: number;
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<data.ResultPagingResponse<data.IPoint[]>>
      >("/admin/member/getMemberPointList", params).then(value => value?.data);
    },

    getClaimList: async (params: {
      member_no: number;
      page: number;
      limit?: number;
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<data.ResultPagingResponse<data.IMemberClaim[]>>
      >("/admin/member/getClaimList", params).then(value => value?.data);
    },

    editSubscribe: async (params: {
      subscribe_no: number;
      card_no: number[];
      delivery_address_no: number;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/member/editSubscribe",
        params,
      ).then(value => value?.data);
    },

    delSubscribe: async (params: {subscribe_no: number}) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/member/delSubscribe",
        params,
      ).then(value => value?.data);
    },

    editSubscribeItemCount: async (params: {
      subscribe_no: number;
      item_no: number;
      count: number;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/member/editSubscribeItemCount",
        params,
      ).then(value => value?.data);
    },

    deleteDeliveryAddress: async (params: {member_no: number; no: number}) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/member/deleteDeliveryAddress",
        params,
      ).then(value => value?.data);
    },

    setMainDeliveryAddress: async (params: {member_no: number; no: number}) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/member/setMainDeliveryAddress",
        params,
      ).then(value => value?.data);
    },

    addDeliveryAddress: async (params: {
      member_no: number;
      zonecode: string;
      sender_name: string;
      sender_phone: string;
      sub_phone: string;
      entrance_yn: data.TypeYN;
      entrance_type: data.TDeliveryEntranceType;
      entrance_memo: string;
      road_address: string;
      jibun_address: string;
      sido: string;
      sigungu: string;
      roadname: string;
      bname: string;
      bname1: string;
      bname2: string;
      detail_address: string;
      query: string;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/member/addDeliveryAddress",
        params,
      ).then(value => value?.data);
    },

    updateDeliveryAddress: async (params: {
      no: number;
      member_no: number;
      sender_name: string;
      sender_phone: string;
      sub_phone: string;
      entrance_yn: data.TypeYN;
      entrance_type: data.TDeliveryEntranceType;
      entrance_memo: string;
      detail_address: string;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/member/updateDeliveryAddress",
        params,
      ).then(value => value?.data);
    },

    deleteCouponOne: async (params: {
      no: number;
      member_no: number;
      coupon_no: number;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/member/deleteCouponOne",
        params,
      ).then(value => value?.data);
    },

    getDeliveryAddressList: async (params: {member_no: number}) => {
      return await axiosPostUtil<data.ApiResponse<data.IDBDeliveryAddress[]>>(
        "/admin/member/getDeliveryAddressList",
        params,
      ).then(value => value?.data);
    },

    createSubscribePass: async (params: {
      member_no: number;
      subscribe_no: number;
      pass_date_list: string[];
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/member/createSubscribePass",
        params,
      ).then(value => value?.data);
    },

    removeSubscribePass: async (params: {
      member_no: number;
      no: number;
      subscribe_no: number;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/member/removeSubscribePass",
        params,
      ).then(value => value?.data);
    },

    removeSubscribeItem: async (params: {
      member_no: number;
      item_no: number;
      subscribe_no: number;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/member/removeSubscribeItem",
        params,
      ).then(value => value?.data);
    },

    getMemberOutRequestList: async ({
      limit = 15,
      page = 1,
      ...rest
    }: {
      limit?: number;
      page?: number;
      searchtxt?: string;
      confirm_yn?: data.TypeYN;
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<data.ResultPagingResponse<data.IMemberOutResponse[]>>
      >("/admin/member/getMemberOutRequestList", {
        limit,
        page,
        ...rest,
      }).then(value => value?.data);
    },

    setMemberOutRequestConfirm: async (params: {no: number}) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/member/setMemberOutRequestConfirm",
        params,
      ).then(value => value?.data);
    },

    getCartList: async (params: {
      member_no: number;
      page: number;
      limit?: number;
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<data.ResultPagingResponse<data.IMemberCart[]>>
      >("/admin/member/getCartList", params).then(value => value?.data);
    },

    deleteToCart: async (params: {
      member_no: number;
      item_no: number;
      subscribe_yn: data.TypeYN;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/member/deleteToCart",
        params,
      ).then(value => value?.data);
    },

    addCartSave: async (params: {
      member_no: number;
      item_list: {
        item_no: number;
        count: number;
      }[];
      subscribe_yn: data.TypeYN;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/member/addCartSave",
        params,
      ).then(value => value?.data);
    },

    changeCartCount: async (params: {
      member_no: number;
      item_no: number;
      count: number;
      subscribe_yn: data.TypeYN;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/member/changeCartCount",
        params,
      ).then(value => value?.data);
    },

    getGradeList: async () => {
      return await axiosPostUtil<data.ApiResponse<data.IGradeResponse[]>>(
        "/admin/member/getGradeList",
      ).then(value => value?.data);
    },

    editGradeData: async (params: {data: data.IGradeCouponEditRequest[]}) => {
      return await axiosPostUtil<data.ApiResponse<data.IGradeResponse[]>>(
        "/admin/member/editGradeData",
        params,
      ).then(value => value?.data);
    },

    getGradeLog: async ({
      page = 1,
      limit = 20,
      ...rest
    }: {
      member_no: number;
      page?: number;
      limit?: number;
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<data.ResultPagingResponse<data.IDBMemberGradeLog[]>>
      >("/admin/member/getGradeLog", {page, limit, ...rest}).then(
        value => value?.data,
      );
    },
  },

  notice: {
    apiGetNoticeList: async (params: {
      limit?: number;
      page: number;
      searchtxt?: string;
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<data.ResultPagingResponse<data.IDBNotice[]>>
      >("/admin/notice/getList", params).then(value => value?.data);
    },

    apiGetNoticeDetail: async (params: {no: number}) => {
      return await axiosPostUtil<data.ApiResponse<data.IDBNotice>>(
        "/admin/notice/getDetail",
        params,
      ).then(value => value?.data);
    },

    apiCreateNotice: async (params: data.IDBNotice) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/notice/createNotice",
        params,
      ).then(value => value?.data);
    },

    apiUpdateNotice: async (params: data.IDBNotice) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/notice/updateNotice",
        params,
      ).then(value => value?.data);
    },

    apiDeleteNotice: async (params: {no: number}) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/notice/deleteNotice",
        params,
      ).then(value => value?.data);
    },
  },

  order: {
    apiGetOrderList: async (params: data.IOrderListRequest) => {
      return await axiosPostUtil<
        data.ApiResponse<
          data.ResultPagingResponse<{
            list?: data.IOrderList[];
            sum?: {
              total_item_count?: number;
              total_order_price?: number;
              avg_distinct_item_count?: number;
            };
          }>
        >
      >("/admin/order/getList", params).then(value => value?.data);
    },

    apiGetOrderDetail: async (params: {no: number}) => {
      return await axiosPostUtil<data.ApiResponse<data.IOrderDetail>>(
        "/admin/order/getDetail",
        params,
      ).then(value => value?.data);
    },

    apiSaveOrderClaim: async (params: {
      no?: number;
      member_no: number;
      order_no?: number;
      content: string;
      important: number;
      type?: string;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/order/updateClaim",
        params,
      ).then(value => value?.data);
    },

    apiExportExcelOrderList: async (params: {list: number[]}) => {
      return await axiosPostUtil<data.ApiResponse<{result: string}>>(
        "/admin/order/exportExcel",
        params,
      ).then(value => value?.data);
    },

    apiExportExcelTeamFreshOrderList: async (params: {list: number[]}) => {
      return await axiosPostUtil<data.ApiResponse<{result: string}>>(
        "/admin/order/exportExcelTeamFresh",
        params,
      ).then(value => value?.data);
    },

    apiUpdateOrderStatus: async (params: {
      no: number;
      status: data.TDBOrderStatus;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/order/statusChange",
        params,
      ).then(value => value?.data);
    },

    apiCancelOrder: async (params: {
      order_no: number;
      type: "cancel" | "refund";
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/order/cancelOrder",
        params,
      ).then(value => value?.data);
    },

    apiCancelOrderPoint: async (params: {order_no: number; point: number}) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/order/cancelOrderPoint",
        params,
      ).then(value => value?.data);
    },

    apiCreateOrderGift: async (params: {
      order_no: number;
      item_no: number;
      count: number;
      isStock: boolean;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/order/createOrderGift",
        params,
      ).then(value => value?.data);
    },

    deleteOrderGift: async (params: {
      order_no: number;
      order_detail_no: number;
      item_no: number;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/order/deleteOrderGift",
        params,
      ).then(value => value?.data);
    },

    updateOrderEstimateOutputDate: async (params: {
      order_no: number;
      estimate_output_date: string;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/order/updateOrderEstimateOutputDate",
        params,
      ).then(value => value?.data);
    },

    updateOrderFinalPrice: async (params: {
      order_no: number;
      final_price: number;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/order/updateOrderFinalPrice",
        params,
      ).then(value => value?.data);
    },

    updateOrderEntrance: async (params: {
      order_no: number;
      entrance_type: data.TDeliveryEntranceType;
      entrance_memo: string;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/order/updateOrderEntrance",
        params,
      ).then(value => value?.data);
    },

    updateOrderExcelMemo: async (params: {
      order_no: number;
      excel_memo: string;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/order/updateOrderExcelMemo",
        params,
      ).then(value => value?.data);
    },

    changeOrderDetailCount: async (params: {
      order_no: number;
      order_detail_no: number;
      item_no: number;
      count: number;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/order/changeOrderDetailCount",
        params,
      ).then(value => value?.data);
    },

    changeOrderDeliveryAddressNo: async (params: {
      order_no: number;
      delivery_address_no: number;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/order/changeOrderDeliveryAddressNo",
        params,
      ).then(value => value?.data);
    },

    createOrder: async (params: {
      member_no: number;
      delivery_address_no: number;
    }) => {
      return await axiosPostUtil<data.ApiResponse<{order_no: number}>>(
        "/admin/order/createOrder",
        params,
      ).then(value => value?.data);
    },

    makeSubscribeOrder: async (params: {member_no: number}) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/api/v1/order/makeSubscribeOrder",
        params,
      ).then(value => value?.data);
    },

    getReOrderCount: async (params: {
      start_date: string;
      end_date: string;
      date_typ?: data.TOrderListDateType;
    }) => {
      return await axiosPostUtil<data.ApiResponse<data.IReOrderCountResponse>>(
        "/admin/order/getReOrderCount",
        params,
      ).then(value => value?.data);
    },
  },

  orderLog: {
    apiGetOrderLogPartner: async (params: {
      end_date: string;
      limit: number;
      page: number;
      start_date: string;
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<
          data.ResultPagingResponse<data.IOrderLogPartnerResponse[]>
        >
      >("/admin/orderLog/getOrderListByPartner", params).then(
        value => value?.data,
      );
    },

    apiGetOrderLogItem: async (params: {
      end_date: string;
      limit: number;
      page: number;
      start_date: string;
      item_partner_no?: number;
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<
          data.ResultPagingResponse<data.IOrderLogItemResponse[]>
        >
      >("/admin/orderLog/getOrderListByItem", params).then(
        value => value?.data,
      );
    },

    apiGetPaymentFailLog: async (params: {
      limit?: number;
      page?: number;
      start_date?: string;
      end_date?: string;
      searchtxt?: string;
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<
          data.ResultPagingResponse<data.IPaymentFailLogResponse[]>
        >
      >("/admin/orderLog/getPaymentFailLog", params).then(value => value?.data);
    },

    getPaymentFailOrderLog: async (params: {
      limit?: number;
      page?: number;
      start_date?: string;
      end_date?: string;
      searchtxt?: string;
      isSuccess?: TStrBoolean;
      member_no?: number;
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<
          data.ResultPagingResponse<data.IPaymentFailOrderLogResponse[]>
        >
      >("/admin/orderLog/getPaymentFailOrderLog", params).then(
        value => value?.data,
      );
    },
  },

  partner: {
    apiGetPartnerList: async ({
      limit = 15,
      page = 1,
      ...rest
    }: {
      limit?: number;
      page?: number;
      searchtxt?: string;
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<{
          result?: data.IDBPartner[];
          page?: data.IPage;
        }>
      >("/admin/partner/getList", {limit, page, ...rest}).then(
        value => value?.data,
      );
    },

    apiCreatePartner: async (data: data.IDBPartner) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/partner/createPartner",
        {
          ...data,
        },
      ).then(value => value?.data);
    },

    apiUpdatePartner: async (data: data.IDBPartner) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/partner/updatePartner",
        {
          ...data,
        },
      ).then(value => value?.data);
    },

    apiDeletePartner: async ({no}: {no: number}) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/partner/deletePartner",
        {
          no,
        },
      ).then(value => value?.data);
    },

    apiGetAllPartners: async () => {
      return await axiosPostUtil<
        data.ApiResponse<{
          result: data.IDBPartner[];
        }>
      >("/admin/partner/getAllPartners").then(value => value?.data);
    },
  },

  payment: {
    addCardSave: async (params: {
      member_no: number;
      cardNumber: string;
      validThru: string;
      birthDay: string;
      password: string;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/payment/addCardSave",
        params,
      ).then(value => value?.data);
    },
  },

  post: {
    getList: async (params: {
      limit?: number;
      page: number;
      searchtxt?: string;
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<data.ResultPagingResponse<data.IPostListResponse[]>>
      >("/admin/post/getList", params).then(value => value?.data);
    },

    toggleYN: async (params: data.IDBPost) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/post/toggleYN",
        params,
      ).then(value => value?.data);
    },

    setOpenDate: async (params: {
      no: number;
      open_date: string;
      hide_yn?: data.TypeYN;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/post/setOpenDate",
        params,
      ).then(value => value?.data);
    },

    createPost: async (params: data.IPostRequest) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/post/createPost",
        params,
      ).then(value => value?.data);
    },

    updatePost: async (params: data.IPostRequest) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/post/updatePost",
        params,
      ).then(value => value?.data);
    },

    getPostRecipeList: async (params: {post_no: number}) => {
      return await axiosPostUtil<data.ApiResponse<data.IPostRecipeItem[]>>(
        "/admin/post/getPostRecipeList",
        params,
      ).then(value => value?.data);
    },

    changePostRecipeOrdering: async (params: {
      no: number;
      post_no: number;
      type: data.TUpDown;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/post/changePostRecipeOrdering",
        params,
      ).then(value => value?.data);
    },

    getPostDetailList: async (params: {post_no: number}) => {
      return await axiosPostUtil<data.ApiResponse<data.IPostDetail[]>>(
        "/admin/post/getPostDetailList",
        params,
      ).then(value => value?.data);
    },

    getPostDetail: async (params: {post_no: number; no: number}) => {
      return await axiosPostUtil<data.ApiResponse<data.IPostDetail>>(
        "/admin/post/getPostDetail",
        params,
      ).then(value => value?.data);
    },

    changePostDetailOrdering: async (params: {
      no: number;
      post_no: number;
      type: data.TUpDown;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/post/changePostDetailOrdering",
        params,
      ).then(value => value?.data);
    },

    createPostDetail: async (params: data.IPostDetailRequest) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/post/createPostDetail",
        params,
      ).then(value => value?.data);
    },

    updatePostDetail: async (params: data.IPostDetailRequest) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/post/updatePostDetail",
        params,
      ).then(value => value?.data);
    },

    deletePostDetail: async (params: {no: number; post_no: number}) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/post/deletePostDetail",
        params,
      ).then(value => value?.data);
    },
  },

  section: {
    getList: async (params: {
      limit?: number;
      page: number;
      searchtxt?: string;
      home_tab_no?: number;
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<data.ResultPagingResponse<data.ISectionList[]>>
      >("/admin/section/getList", params).then(value => value?.data);
    },

    getDetail: async (params: {no: number}) => {
      return await axiosPostUtil<data.ApiResponse<data.IDBSection>>(
        "/admin/section/getDetail",
        params,
      ).then(value => value?.data);
    },

    changeSectionOrdering: async (params: {no: number; type: data.TUpDown}) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/section/changeSectionOrdering",
        params,
      ).then(value => value?.data);
    },

    toggleHideYn: async (params: {no: number; hide_yn: data.TypeYN}) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/section/toggleHideYn",
        params,
      ).then(value => value?.data);
    },

    getSectionContentList: async (params: {no: number}) => {
      return await axiosPostUtil<data.ApiResponse<data.IDBSectionContents[]>>(
        "/admin/section/getSectionContentList",
        params,
      ).then(value => value?.data);
    },

    getSectionDetailList: async (params: {no: number}) => {
      return await axiosPostUtil<data.ApiResponse<data.ISectionDetail[]>>(
        "/admin/section/getSectionDetailList",
        params,
      ).then(value => value?.data);
    },

    createSection: async (params: data.ISectionRequest) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/section/createSection",
        params,
      ).then(value => value?.data);
    },

    updateSection: async (params: data.ISectionRequest) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/section/updateSection",
        params,
      ).then(value => value?.data);
    },

    deleteSection: async (params: {no: number}) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/section/deleteSection",
        params,
      ).then(value => value?.data);
    },

    deleteSectionContent: async (params: {no: number; section_no: number}) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/section/deleteSectionContent",
        params,
      ).then(value => value?.data);
    },

    getSectionContentDetail: async (params: {
      no: number;
      section_no: number;
    }) => {
      return await axiosPostUtil<data.ApiResponse<data.ISectionContents>>(
        "/admin/section/getSectionContentDetail",
        params,
      ).then(value => value?.data);
    },

    createSectionContent: async (params: data.ISectionContentsRequest) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/section/createSectionContent",
        params,
      ).then(value => value?.data);
    },

    updateSectionContent: async (params: data.ISectionContentsRequest) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/section/updateSectionContent",
        params,
      ).then(value => value?.data);
    },

    changeSectionContentOrdering: async (params: {
      no: number;
      section_no: number;
      type: data.TUpDown;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/section/changeSectionContentOrdering",
        params,
      ).then(value => value?.data);
    },

    changeSectionDetailOrdering: async (params: {
      no: number;
      section_no: number;
      type: data.TUpDown;
    }) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/section/changeSectionDetailOrdering",
        params,
      ).then(value => value?.data);
    },

    copySection: async (params: {no: number}) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/admin/section/copySection",
        params,
      ).then(value => value?.data);
    },
  },

  smsLog: {
    getList: async (params: {
      limit: number;
      page: number;
      searchtxt?: string;
      success?: "1" | "0" | "";
      type: data.TSmsLogType;
    }) => {
      return await axiosPostUtil<
        data.ApiResponse<data.ResultPagingResponse<data.ISmsLogListResponse[]>>
      >("/admin/smsLog/getList", params).then(value => value?.data);
    },
  },

  utils: {
    uploadImgThumb: async (params: {url_list: string[]; width?: number}) => {
      return await axiosPostUtil<data.ApiResponse<string[]>>(
        "/admin/utils/uploadImgThumb",
        params,
      ).then(value => value?.data);
    },
  },

  apiGrade: {
    updateVVIPGradeManual: async (startDate: string, endDate: string) => {
      return await axiosPostUtil<data.ApiResponse<string[]>>(
        "/api/v2/batch/membership-vvip",
        {startDate, endDate},
      ).then(value => value?.data);
    },

    updateMemberGradeMonthlyManual: async (
      startDate: string,
      endDate: string,
    ) => {
      return await axiosPostUtil<data.ApiResponse<string[]>>(
        "/api/v2/batch/membership-grades",
        {startDate, endDate},
      ).then(value => value?.data);
    },

    createForceOrder: async (
      orderDate: string,
      amount: number,
      memberNo: number,
    ) => {
      return await axiosPostUtil<data.ApiResponse<string[]>>(
        "/api/v2/batch/force-order",
        {orderDate, amount, memberNo},
      ).then(value => value?.data);
    },

    updateMemberPoint: async (initDate: string) => {
      return await axiosPostUtil<data.ApiResponse>(
        "/api/v2/batch/membership-point",
        {initDate},
      ).then(value => value?.data);
    },

    updateMemberCoupon: async () => {
      return await axiosPostUtil<data.ApiResponse>(
        "/api/v2/batch/membership-rewards",
      ).then(value => value?.data);
    },
  },

  promotion: {
    getPromotionList: async (subject?: string) => {
      const url = `/api/v2/promotion/items${
        subject ? `?subject=${subject}` : ""
      }`;
      return await axiosGetUtil<data.ApiResponse>(url).then(
        value => value?.data,
      );
    },
  },
};

export default adminAPI;
