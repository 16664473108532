import React, {useEffect, useState} from "react";
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  Button,
  InputBox,
  TextAreaBox,
  SelectBox,
  FileUploadButton,
} from "@components";
import {adminAPI, styleUtils, utils} from "@utils";
import {
  IBannerDetail,
  IDBProduct,
  IBanner,
  bannerDetailLayout,
  TBannerDetailLayout,
  IDeeplinkContentNoListResponse,
} from "@data";
import styles from "./style.module.scss";
import {deeplinkArr} from "utils/constant";
import FlatListBox from "../../components/FlatListBox";

interface IProps {
  isVisible?: boolean;
  data?: IBanner;
  setData?: (data?: IBanner) => void;
  save?: (data: {items: number[]; details: IBannerDetail[]}) => void;
  close?: () => void;
}

export function BannerDetailModal({
  isVisible,
  data = {},
  setData,
  save,
  close,
}: IProps) {
  const [itemList, setItemList] = useState<IDBProduct[]>([]);
  const [itemNoList, setItemNoList] = useState<string[]>([]);
  const [details, setDetails] = useState<IBannerDetail[]>();
  const [targetList, setTargetList] = useState<{
    [key: string]: IDeeplinkContentNoListResponse[];
  }>({});
  const [forceRender, setForceRender] = useState(0);
  const triggerRender = () => setForceRender(forceRender + 1);

  async function getItemList() {
    try {
      const {data, success, message} = await adminAPI.item.apiGetAllItems({});
      if (success) {
        setItemList(data.result);
      }
    } catch (error) {
      console.log("getItemList error", error);
    }
  }

  function setClose() {
    setItemNoList([]);
    setDetails(undefined);
    setData?.(undefined);
    close?.();
  }

  function setSave() {
    save?.({
      items: itemNoList.map(item => Number(item)),
      details: details || [],
    });
  }

  async function getContentNoList(deeplink: string) {
    try {
      if (!deeplink || !!targetList[deeplink]) {
        return;
      }
      const {
        data: apiData,
        success,
        message,
      } = await adminAPI.marketing.getContentNoList({
        type: deeplink,
        hide_yn: "N",
      });
      if (success) {
        setTargetList({...targetList, [deeplink]: apiData.result});
      } else {
        alert(message || "오류가 발생했습니다");
      }
    } catch (error) {
      console.log("getContentNoList error", error);
    }
  }

  useEffect(() => {
    getItemList();
  }, []);

  useEffect(() => {
    if ((data?.items || []).length > 0) {
      setItemNoList((data.items || []).map(item => String(item.item_no)));
    }
    const deeplinkTargets = [
      ...new Set(
        (data.detail || [])
          .filter(_item => _item.layout_type === "carousel" && !!_item.deeplink)
          .map(_item => _item.deeplink as string),
      ),
    ];
    if (deeplinkTargets.length > 0) {
      for (const deeplink of deeplinkTargets) {
        getContentNoList(deeplink);
      }
    }
    if (isVisible) {
      if (!details && !!data) {
        if ((data.detail || []).length === 0) {
          setDetails([{}]);
        } else {
          setDetails(data.detail || []);
        }
      }
    } else {
      setDetails(undefined);
    }
  }, [data, isVisible]);

  return (
    <Modal
      isVisible={isVisible}
      width={"500px"}
      close={setClose}
      className={styles.modal}>
      <ModalTitle title="상세페이지" isClose close={setClose} />
      <ModalContent>
        {/*!-----숨김 처리됨*/}
        <div className="row top hide">
          <div className="col title">이벤트 상품 타이틀</div>
          <div className="col">
            <InputBox
              placeholder="이벤트 상품 타이틀"
              value={data?.items_title}
              onValue={value => setData?.({...data, items_title: value})}
            />
          </div>
        </div>
        <div className="row top hide">
          <div className="col title">상품선택</div>
          <div className="col">
            <SelectBox
              multiple
              list={(itemList || []).map(item => ({
                value: String(item.no),
                label: String(item.item_name || ""),
              }))}
              value={itemNoList}
              multipleSelect={setItemNoList}
            />
          </div>
        </div>
        {/*------숨김 처리됨*/}
        <div className="row">
          <div className="col flex-end">
            <div>
              <Button
                text="추가"
                onClick={() => setDetails([...(details || []), {}])}
              />
            </div>
          </div>
        </div>
        <>
          {(details || []).map((detail, index) => {
            const img_url = detail.thumbnail_img_url || detail.img_url;
            const banner_img_url =
              detail.banner_thumbnail_img_url || detail.banner_img_url;
            return (
              <div
                key={index}
                style={{
                  borderTop: "1px solid #eee",
                  padding: 6,
                  marginTop: 6,
                }}>
                <div className="row">
                  <div className="col">
                    <InputBox
                      placeholder="타이틀"
                      value={detail?.title}
                      onValue={value =>
                        setDetails([
                          ...(details || []).map((_detail, j) => {
                            if (index === j) {
                              return {..._detail, title: value};
                            }
                            return _detail;
                          }),
                        ])
                      }
                    />
                  </div>
                  <div>
                    <Button
                      text="제거"
                      height={30}
                      fontSize={12}
                      backgroundColor={styleUtils.COLOR.red400}
                      onClick={() =>
                        setDetails([
                          ...(details || []).filter((__, j) => index !== j),
                        ])
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  <TextAreaBox
                    placeholder="내용"
                    value={detail?.content}
                    height={150}
                    onValue={value =>
                      setDetails([
                        ...(details || []).map((_detail, j) => {
                          if (index === j) {
                            return {..._detail, content: value};
                          }
                          return _detail;
                        }),
                      ])
                    }
                  />
                </div>
                <div className="row top">
                  <div className="col title">레이아웃</div>
                  <div className="col">
                    <SelectBox
                      list={bannerDetailLayout}
                      value={detail?.layout_type}
                      singleSelect={value =>
                        setDetails([
                          ...(details || []).map((_detail, j) => {
                            if (index === j) {
                              return {
                                ..._detail,
                                layout_type: value as TBannerDetailLayout,
                              };
                            }
                            return _detail;
                          }),
                        ])
                      }
                    />
                  </div>
                </div>
                {detail?.layout_type === "set" && (
                  <>
                    <div className="row">
                      <div className="col title">
                        <div className="buttonCol">
                          배너 이미지
                          <div>
                            <FileUploadButton
                              text={"업로드"}
                              height={32}
                              width={32}
                              uploadUrl={"/admin/utils/uploadBannerImg"}
                              fileUpload={({files}) =>
                                setDetails([
                                  ...(details || []).map((_detail, j) => {
                                    if (index === j) {
                                      return {
                                        ..._detail,
                                        banner_img_url: files?.[0],
                                      };
                                    }
                                    return _detail;
                                  }),
                                ])
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        {!!banner_img_url && (
                          <div className={styles.imgRow}>
                            <div>
                              <img src={banner_img_url} />
                              <div
                                className={styles.deleteBtn}
                                onClick={() =>
                                  setDetails([
                                    ...(details || []).map((_detail, j) => {
                                      if (index === j) {
                                        return {
                                          ..._detail,
                                          banner_img_url: undefined,
                                          banner_thumbnail_img_url: undefined,
                                        };
                                      }
                                      return _detail;
                                    }),
                                  ])
                                }>
                                X
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <InputBox
                        placeholder="배너 제목"
                        value={detail?.banner_title}
                        onValue={value =>
                          setDetails([
                            ...(details || []).map((_detail, j) => {
                              if (index === j) {
                                return {..._detail, banner_title: value};
                              }
                              return _detail;
                            }),
                          ])
                        }
                      />
                    </div>
                    <div className="row">
                      <InputBox
                        placeholder="배너 소제목"
                        value={detail?.banner_sub_title}
                        onValue={value =>
                          setDetails([
                            ...(details || []).map((_detail, j) => {
                              if (index === j) {
                                return {..._detail, banner_sub_title: value};
                              }
                              return _detail;
                            }),
                          ])
                        }
                      />
                    </div>
                    <div className="row">
                      <TextAreaBox
                        placeholder="본문 내용(공백 포함 90자, 미기입가능)"
                        value={detail?.banner_content}
                        maxLength={90}
                        height={80}
                        onValue={value =>
                          setDetails([
                            ...(details || []).map((_detail, j) => {
                              if (index === j) {
                                return {..._detail, banner_content: value};
                              }
                              return _detail;
                            }),
                          ])
                        }
                      />
                    </div>
                    <div className="row">
                      <InputBox
                        placeholder="버튼명 수정, 미기입 시, [더보기]로 입력됩니다."
                        value={detail?.banner_btn_title}
                        onValue={value =>
                          setDetails([
                            ...(details || []).map((_detail, j) => {
                              if (index === j) {
                                return {..._detail, banner_btn_title: value};
                              }
                              return _detail;
                            }),
                          ])
                        }
                      />
                    </div>
                    <div className="row">
                      <div className="col title">딥링크</div>
                      <div className="col">
                        <SelectBox
                          list={[
                            {label: "미선택", value: ""},
                            ...(deeplinkArr || []),
                          ]}
                          value={detail?.deeplink}
                          singleSelect={value => {
                            getContentNoList(value);
                            setDetails([
                              ...(details || []).map((_detail, j) => {
                                if (index === j) {
                                  return {..._detail, deeplink: value};
                                }
                                return _detail;
                              }),
                            ]);
                          }}
                        />
                      </div>
                    </div>
                    <>
                      {!!detail?.deeplink &&
                        (targetList[detail.deeplink] || []).length > 0 && (
                          <div className="row">
                            <div className="col title">딥링크타겟</div>
                            <div className="col">
                              <SelectBox
                                list={(targetList[detail.deeplink] || []).map(
                                  item => ({
                                    value: String(item.no),
                                    label: String(item.title || ""),
                                  }),
                                )}
                                value={String(detail.target_no)}
                                singleSelect={value =>
                                  setDetails([
                                    ...(details || []).map((_detail, j) => {
                                      if (index === j) {
                                        return {
                                          ..._detail,
                                          target_no: Number(value),
                                        };
                                      }
                                      return _detail;
                                    }),
                                  ])
                                }
                              />
                            </div>
                          </div>
                        )}
                    </>
                  </>
                )}
                <div className="row top">
                  <div className="col title">상품선택</div>
                </div>
                <div className="row top">
                  <div className="col">
                    <FlatListBox
                      multiple
                      list={(itemList || []).map(item => ({
                        value: String(item.no),
                        label:
                          `[${item.partners?.partner_name}] ${item.item_name}` ||
                          "",
                      }))}
                      value={(detail.items || [])
                        .filter(utils.isDefined)
                        .map(item => String(item))} // 현재 선택된 값
                      orderedValues={(detail.items || []).map(item =>
                        String(item),
                      )} // 순서 보장
                      onOrderChange={newOrder => {
                        setDetails(prevDetails =>
                          (prevDetails || []).map((d, i) =>
                            i === index
                              ? {...d, items: newOrder.map(Number)}
                              : d,
                          ),
                        );
                      }}
                      onSelect={values => {
                        if (Array.isArray(values)) {
                          setDetails(prevDetails =>
                            (prevDetails || []).map((d, i) =>
                              i === index
                                ? {...d, items: values.map(Number)}
                                : d,
                            ),
                          );
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col title">
                    <div className="buttonCol">
                      이미지
                      <div>
                        <FileUploadButton
                          text={"업로드"}
                          height={32}
                          width={32}
                          uploadUrl={"/admin/utils/uploadBannerImg"}
                          fileUpload={({files}) =>
                            setDetails([
                              ...(details || []).map((_detail, j) => {
                                if (index === j) {
                                  return {..._detail, img_url: files?.[0]};
                                }
                                return _detail;
                              }),
                            ])
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    {!!img_url && (
                      <div className={styles.imgRow}>
                        <div>
                          <img src={img_url} />
                          <div
                            className={styles.deleteBtn}
                            onClick={() =>
                              setDetails([
                                ...(details || []).map((_detail, j) => {
                                  if (index === j) {
                                    return {
                                      ..._detail,
                                      img_url: undefined,
                                      thumbnail_img_url: undefined,
                                    };
                                  }
                                  return _detail;
                                }),
                              ])
                            }>
                            X
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </>
      </ModalContent>
      <ModalFooter>
        <div></div>
        <div>
          <Button text="저장" onClick={setSave} />
        </div>
      </ModalFooter>
    </Modal>
  );
}
