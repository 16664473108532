import {
  Button,
  CheckBox,
  InputBox,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  RadioBox,
  SelectBox,
  TextAreaBox,
  TimePicker,
} from "@components";
import {
  IDeeplinkType,
  IMarketingLogFilter,
  TDeeplinkValue,
  TDeliveryType,
  TFilterSelectUpDown,
  TypeYN,
  TypeYNWithEmpty,
  IDBCouponList,
  IDBPartner,
  IDBPetKind,
  IDBProduct,
  IMarketingSaveRequest,
  IDeeplinkContentNoListResponse,
  IMarketingPrevCount,
} from "@data";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import {states} from "@recoils";
import {adminAPI, constant, excelUtil, styleUtils} from "@utils";
import {convertMarketingSelect} from ".";
import styles from "./style.module.scss";

interface IProps {
  isVisible: boolean;
  getList?: () => void;
  close?: () => void;
  deeplinkArr?: IDeeplinkType[];
}

export function MarketingAddModal({
  isVisible,
  getList,
  close,
  deeplinkArr,
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);

  const [partnerList, setPartnerList] = useState<IDBPartner[]>([]);
  const [itemList, setItemList] = useState<IDBProduct[]>([]);
  const [petKindList, setPetKindList] = useState<IDBPetKind[]>([]);
  const [couponList, setCouponList] = useState<IDBCouponList[]>([]);
  const [prevUserCount, setPrevUserCount] = useState<IMarketingPrevCount>();
  const [targetList, setTargetList] =
    useState<IDeeplinkContentNoListResponse[]>();

  const [createdAtSdate, setCreatedAtSdate] = useState("");
  const [createdAtEdate, setCreatedAtEdate] = useState("");
  const [orderSdate, setOrderSdate] = useState("");
  const [orderEdate, setOrderEdate] = useState("");
  const [lastOrderSdate, setLastOrderSdate] = useState("");
  const [lastOrderEdate, setLastOrderEdate] = useState("");
  const [partnerNo, setPartnerNo] = useState<number>();
  const [itemNoList, setItemNoList] = useState<string[]>([]);
  const [orderCnt, setOrderCnt] = useState<number>(0);
  const [orderCntSelect, setOrderCntSelect] =
    useState<TFilterSelectUpDown>("up");
  const [orderAllCnt, setOrderAllCnt] = useState<number>(0);
  const [orderAllCntSelect, setOrderAllCntSelect] =
    useState<TFilterSelectUpDown>("up");
  const [cartYN, setCartYN] = useState<TypeYNWithEmpty>();
  const [useCouponNo, setUseCouponNo] = useState<number>();
  const [expireCouponNo, setExpireCouponNo] = useState<number>();
  const [petKind, setPetKind] = useState<string>();
  const [deliveryType, setDeliveryType] = useState<TDeliveryType>();
  const [subscriptionYN, setSubscriptionYN] = useState<TypeYNWithEmpty>();
  const [gradeList, setGradeList] = useState<string[]>([]);
  const [pushYn, setPushYn] = useState<TypeYNWithEmpty>();
  const [includeNonMember, setIncludeNonMember] = useState<TypeYN>("Y");

  const [isReservation, setIsReservation] = useState<boolean>(!!true);
  const [reservationDate, setReservationDate] = useState<string>();
  const [reservationTime, setReservationTime] = useState<string>();
  const reservationDateTime =
    !!reservationDate && !!reservationTime
      ? moment([reservationDate, reservationTime].join(" "))
      : undefined;

  const [test, setTest] = useState(false);
  const [deeplink, setDeeplink] = useState<TDeeplinkValue>();
  const [targetNo, setTargetNo] = useState<string>();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const [couponImmediateYn, setCouponImmediateYn] = useState<TypeYN>("Y");
  const [useNotCouponNoArr, setUseNotCouponNoArr] = useState<string[]>([]);
  const [couponNo, setCouponNo] = useState<string>();
  const [couponLimitDate, setCouponLimitDate] = useState<string>();
  const [couponLimitTime, setCouponLimitTime] = useState<string>();
  const couponLimitDateTime =
    !!couponLimitDate && !!couponLimitTime
      ? moment([couponLimitDate, couponLimitTime].join(" "))
      : undefined;
  const [couponPopupTitle, setCouponPopupTitle] = useState<string>();
  const [couponPopupContent, setCouponPopupContent] = useState<string>();

  const nowTime = moment();

  const filterJson: IMarketingLogFilter = {
    test,
    createdAt_sdate: createdAtSdate
      ? moment(createdAtSdate).format("YYYY-MM-DD HH:mm:ss")
      : "",
    createdAt_edate: createdAtEdate
      ? moment(createdAtEdate).format("YYYY-MM-DD HH:mm:ss")
      : "",
    order_sdate: orderSdate
      ? moment(orderSdate).format("YYYY-MM-DD HH:mm:ss")
      : "",
    order_edate: orderEdate
      ? moment(orderEdate).format("YYYY-MM-DD HH:mm:ss")
      : "",
    last_order_sdate: lastOrderSdate
      ? moment(lastOrderSdate).format("YYYY-MM-DD HH:mm:ss")
      : "",
    last_order_edate: lastOrderEdate
      ? moment(lastOrderEdate).format("YYYY-MM-DD HH:mm:ss")
      : "",
    orderCnt: String(orderCnt ?? ""),
    orderCnt_select: orderCntSelect,
    orderAllCnt: String(orderAllCnt ?? ""),
    orderAllCnt_select: orderAllCntSelect,
    partner_no: String(partnerNo || ""),
    item_no: itemNoList,
    petKind: petKind,
    delivery_type: deliveryType ? [deliveryType] : [],
    subscription_yn: subscriptionYN,
    gradeList: gradeList.map(item => Number(item)),
    push_yn: pushYn,
    include_nonMember: includeNonMember,
    cart_yn: cartYN,
    use_coupon_no: String(useCouponNo || ""),
    expire_coupon_no: String(expireCouponNo || ""),
    use_not_coupon_no: useNotCouponNoArr,
  };

  async function getPartnerList() {
    try {
      const {data, success, message} =
        await adminAPI.partner.apiGetAllPartners();
      if (success) {
        setPartnerList(data.result);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.log("getPartnerList error", error);
    }
  }

  async function getItemList() {
    try {
      setItemNoList([]);

      const {data, success, message} = await adminAPI.item.apiGetAllItems({
        partner_no: partnerNo,
      });
      if (success) {
        setItemList(data.result);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.log("getItemList error", error);
    }
  }

  async function getCouponList() {
    try {
      const {data, success, message} =
        await adminAPI.coupon.apiGetAllCouponList();
      if (success) {
        setCouponList(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.log("getCouponList error", error);
    }
  }

  async function getPetKindList() {
    try {
      const {data, success, message} = await adminAPI.item.getPetKindList();
      if (success) {
        setPetKindList(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.log("getPetKindList error", error);
    }
  }

  async function getContentNoList() {
    try {
      setTargetList(undefined);
      setTargetNo(undefined);
      if (!deeplink) {
        return;
      }
      const {data, success, message} =
        await adminAPI.marketing.getContentNoList({
          type: deeplink,
          hide_yn: ["postDetail"].includes(deeplink) ? undefined : "N",
        });
      if (success) {
        setTargetList(data.result);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.log("getContentNoList error", error);
    }
  }

  async function getPrevUserCount() {
    try {
      const {data, success, message} =
        await adminAPI.marketing.apiGetPrevPushSendCount(filterJson);
      if (success) {
        setPrevUserCount(data.result);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.log("getPrevUserCount error", error);
    }
  }

  async function saveMarketingTemp() {
    try {
      const target = (targetList || []).find(
        item => item.no === Number(targetNo),
      );
      if (deeplink === "postDetail" && !!target && target?.hide_yn === "Y") {
        showPostDetail({callback: saveMarketing});
      } else {
        saveMarketing();
      }
    } catch (error) {
      console.error("saveMarketingTemp error", error);
    }
  }

  async function showPostDetail({callback}: {callback: () => void}) {
    try {
      if (isNaN(Number(targetNo))) {
        return;
      }
      if (
        !confirm(
          "선택하신 한상차림이 현재 비공개입니다. 노출일자를 푸시발송시간으로 변경하시겠습니까?",
        )
      ) {
        return callback();
      }
      const {success, message} = await adminAPI.post.setOpenDate({
        no: Number(targetNo),
        open_date: moment(reservationDateTime).format("YYYY-MM-DD HH:mm:ss"),
        hide_yn: "N",
      });
      if (success) {
        callback();
      } else {
        return setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.log("showPostDetail error", error);
    }
  }

  async function saveMarketing() {
    try {
      if (!title) {
        return setAlertModal({
          isVisible: true,
          title: "타이틀을 입력해주세요.",
        });
      }
      if (!content) {
        return setAlertModal({
          isVisible: true,
          title: "내용을 입력해주세요.",
        });
      }
      if (
        isReservation &&
        (!reservationDateTime || !moment(reservationDateTime).isValid())
      ) {
        return setAlertModal({
          isVisible: true,
          title: "발송예약일시를 확인해주세요.",
        });
      }
      if (
        isReservation &&
        moment(reservationDateTime).diff(moment()) < 10 * 60 * 1000
      ) {
        return setAlertModal({
          isVisible: true,
          title: "예약발송은 10분 이후부터 가능합니다.",
        });
      }
      if (!!couponNo && !moment(couponLimitDateTime).isValid()) {
        return setAlertModal({
          isVisible: true,
          title: "쿠폰만료일시를 확인해주세요.",
        });
      }
      if (
        !confirm(
          isReservation
            ? "푸시를 발송하시겠습니까? 확인 하시면 예약시간에 푸시가 발송됩니다."
            : "푸시를 발송하시겠습니까? 확인 하시면 즉시 푸시가 발송됩니다.",
        )
      ) {
        return;
      }

      const params: IMarketingSaveRequest = {
        title,
        content,
        type: deeplink,
        target_no: targetNo ? Number(targetNo) : undefined,
        reservation_datetime: reservationDateTime
          ? moment(reservationDateTime).format("YYYY-MM-DD HH:mm:ss")
          : "",
        coupon_no: couponNo ? Number(couponNo) : undefined,
        coupon_limit_date: couponLimitDateTime
          ? moment(couponLimitDateTime).format("YYYY-MM-DD HH:mm:ss")
          : "",
        popup_title: couponPopupTitle,
        popup_content: couponPopupContent,
        coupon_immediate_yn: couponImmediateYn,
        ...filterJson,
      };

      const {success, message} = await (isReservation
        ? adminAPI.marketing.apiReservationPushNotification(params)
        : adminAPI.marketing.apiSendPushNotification(params));
      if (success) {
        getList?.();
        close?.();
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.log("saveMarketing error", error);
    }
  }

  async function memberListExcelDownload() {
    try {
      const {success, data, message} =
        await adminAPI.marketing.apiExportExcelMarketingMemberList(filterJson);
      if (success) {
        excelUtil.excelDownload({
          excelData: data.result,
          filename: "memberFile",
        });
      } else {
        return setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.log("memberListExcelDownload error", error);
    }
  }

  useEffect(() => {
    getItemList();
    setItemNoList([]);
  }, [partnerNo]);

  useEffect(() => {
    const pushType = (deeplinkArr || []).find(item => item.value === deeplink);
    if (pushType?.requireno) {
      getContentNoList();
    } else {
      setTargetList(undefined);
      setTargetNo(undefined);
    }
  }, [deeplink]);

  useEffect(() => {
    if (!isReservation) {
      setReservationDate("");
      setReservationTime("");
    }
  }, [isReservation]);

  useEffect(() => {
    if (!couponNo) {
      setCouponLimitDate("");
      setCouponLimitTime("");
      setCouponPopupTitle("");
      setCouponPopupContent("");
    }
  }, [couponNo]);

  useEffect(() => {
    if (!isVisible) {
      setCreatedAtSdate("");
      setCreatedAtEdate("");
      setOrderSdate("");
      setOrderEdate("");
      setPartnerNo(undefined);
      setItemNoList([]);
      setOrderCnt(0);
      setOrderCntSelect("up");
      setOrderAllCnt(0);
      setOrderAllCntSelect("up");
      setCartYN(undefined);
      setPetKind(undefined);
      setDeliveryType(undefined);
      setSubscriptionYN(undefined);
      setGradeList([]);
      setPushYn(undefined);
      setIncludeNonMember("Y");

      setPrevUserCount(undefined);

      setIsReservation(true);
      setTest(false);
      setDeeplink(undefined);
      setTitle("");
      setContent("");

      setCouponNo(undefined);
    }
  }, [isVisible]);

  useEffect(() => {
    getPartnerList();
    getCouponList();
    getPetKindList();
  }, []);

  if (!isVisible) {
    return <></>;
  }

  return (
    <Modal isVisible={isVisible} width={"700px"} close={close}>
      <ModalTitle title="푸시등록" isClose close={close} />
      <ModalContent>
        <div className="row">
          <div className="col title">발송조건</div>
        </div>
        <div className="row">
          <div className="col title">가입일자</div>
          <div className="col">
            <div className="row center">
              <div className="col">
                <InputBox
                  value={createdAtSdate}
                  type={"date"}
                  onValue={setCreatedAtSdate}
                />
              </div>
              <div className="col">~</div>
              <div className="col">
                <InputBox
                  value={createdAtEdate}
                  type={"date"}
                  onValue={setCreatedAtEdate}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col title">구매(주문)일자</div>
          <div className="col">
            <div className="row center">
              <div className="col">
                <InputBox
                  value={orderSdate}
                  type={"date"}
                  onValue={setOrderSdate}
                />
              </div>
              <div className="col">~</div>
              <div className="col">
                <InputBox
                  value={orderEdate}
                  type={"date"}
                  onValue={setOrderEdate}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col title">ㄴ 구매횟수</div>
          <div className="col">
            <div className="row">
              <div className="col">
                <SelectBox
                  list={[
                    {no: "", partner_name: "업체선택"},
                    ...partnerList,
                  ].map(item => ({
                    value: String(item.no),
                    label: String(item.partner_name || ""),
                  }))}
                  value={String(partnerNo)}
                  singleSelect={e => {
                    const activeNo = Number(e);
                    setPartnerNo(isNaN(activeNo) ? undefined : activeNo);
                  }}
                />
              </div>
              <div className="col" style={{maxWidth: 150}}>
                <SelectBox
                  multiple
                  list={[{no: "", item_name: "상품선택"}, ...itemList].map(
                    item => ({
                      value: String(item.no),
                      label: String(item.item_name || ""),
                    }),
                  )}
                  value={itemNoList}
                  multipleSelect={value => {
                    setItemNoList(value);
                  }}
                />
              </div>
              <div className="col">
                <InputBox
                  value={orderCnt}
                  type={"number"}
                  onValue={value => setOrderCnt(Number(value || 0))}
                  style={{width: 30}}
                  rightContent={<span>회</span>}
                />
              </div>
              <div className="col">
                <SelectBox
                  list={(["up", "down", "equal"] as TFilterSelectUpDown[]).map(
                    item => ({
                      label: convertMarketingSelect[item],
                      value: item,
                    }),
                  )}
                  value={orderCntSelect}
                  singleSelect={e =>
                    setOrderCntSelect(e as TFilterSelectUpDown)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col text-12">
            (구매(주문)일자가{" "}
            {orderSdate || orderEdate ? (
              orderSdate && orderEdate ? (
                <>
                  {moment(orderSdate).format("YYYY-MM-DD")} ~{" "}
                  {moment(orderEdate).format("YYYY-MM-DD")}
                </>
              ) : orderSdate ? (
                <>
                  {moment(orderSdate).format("YYYY-MM-DD")}일 부터 발송시점까지
                </>
              ) : (
                <>
                  앱오픈일부터 {moment(orderEdate).format("YYYY-MM-DD")}일 까지
                </>
              )
            ) : (
              "전체기간"
            )}{" "}
            이고,{" "}
            {!!partnerNo || (itemNoList || []).length > 0
              ? "선택업체/상품의 "
              : ""}
            구매횟수가 {orderCnt || 0}회
            {orderCntSelect === "equal"
              ? "와 같음"
              : orderCntSelect === "down"
              ? "보다 같거나 작음"
              : "보다 같거나 큼"}
            )
          </div>
        </div>
        <div className="row">
          <div className="col title">주문건 (전체기간)</div>
          <div className="col">
            <div className="row">
              <div className="col">
                <InputBox
                  value={orderAllCnt}
                  type={"number"}
                  onValue={value => setOrderAllCnt(Number(value))}
                  rightContent={<span>회</span>}
                />
              </div>
              <div className="col">
                <SelectBox
                  list={(["up", "down", "equal"] as TFilterSelectUpDown[]).map(
                    item => ({
                      label: convertMarketingSelect[item],
                      value: item,
                    }),
                  )}
                  value={orderAllCntSelect}
                  singleSelect={e =>
                    setOrderAllCntSelect(e as TFilterSelectUpDown)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col title">마지막 구매(주문)일자</div>
          <div className="col">
            <div className="row center">
              <div className="col">
                <InputBox
                  value={lastOrderSdate}
                  type={"date"}
                  onValue={setLastOrderSdate}
                />
              </div>
              <div className="col">~</div>
              <div className="col">
                <InputBox
                  value={lastOrderEdate}
                  type={"date"}
                  onValue={setLastOrderEdate}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col title">특정쿠폰 사용자</div>
          <div className="col">
            <SelectBox
              list={[
                {no: "", coupon_num: "미선택", title: ""},
                ...couponList,
              ].map(item => ({
                value: String(item.no),
                label: `[${item.coupon_num}] ${item.title}`,
              }))}
              value={String(useCouponNo)}
              singleSelect={e => setUseCouponNo(Number(e))}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">특정쿠폰 미발행자</div>
          <div className="col">
            <SelectBox
              multiple
              list={couponList.map(item => ({
                value: String(item.no),
                label: `[${item.coupon_num}] ${item.title}`,
              }))}
              value={useNotCouponNoArr}
              multipleSelect={e => setUseNotCouponNoArr(e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">특정쿠폰 오늘만료</div>
          <div className="col">
            <SelectBox
              list={[
                {no: "", coupon_num: "미선택", title: ""},
                ...couponList,
              ].map(item => ({
                value: String(item.no),
                label: `[${item.coupon_num}] ${item.title}`,
              }))}
              value={String(expireCouponNo)}
              singleSelect={e => setExpireCouponNo(Number(e))}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">장바구니상품</div>
          <div className="col">
            <SelectBox
              list={(["", "Y", "N"] as TypeYNWithEmpty[]).map(item => ({
                label: item === "Y" ? "있음" : item === "N" ? "없음" : "미선택",
                value: item,
              }))}
              value={cartYN}
              singleSelect={e => setCartYN(e as TypeYNWithEmpty)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">아이종류</div>
          <div className="col">
            <SelectBox
              list={[{no: "", kind_name: "미선택"}, ...petKindList].map(
                item => ({
                  value: String(item.no),
                  label: String(item.kind_name || ""),
                }),
              )}
              value={petKind}
              singleSelect={setPetKind}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">기본배송지구분</div>
          <div className="col">
            <SelectBox
              list={["", ...constant.deliveryTypeList].map(item => ({
                value: item,
                label: item || "전체",
              }))}
              value={deliveryType}
              singleSelect={e => {
                setDeliveryType(e as TDeliveryType);
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">정기구독</div>
          <div className="col">
            <SelectBox
              list={(["", "Y", "N"] as TypeYNWithEmpty[]).map(item => ({
                label:
                  item === "Y"
                    ? "구독중"
                    : item === "N"
                    ? "구독안함"
                    : "선택안함",
                value: item,
              }))}
              value={subscriptionYN}
              singleSelect={e => setSubscriptionYN(e as TypeYNWithEmpty)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">등급</div>
          <div className="col">
            <SelectBox
              list={constant.GRADE_LIST.filter(item => !!item.value)}
              value={gradeList}
              multiple
              multipleSelect={value => setGradeList(value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">앱알림</div>
          <div className="col">
            <SelectBox
              list={(["", "Y", "N"] as TypeYNWithEmpty[]).map(item => ({
                label:
                  item === "Y"
                    ? "수신동의"
                    : item === "N"
                    ? "수신거부"
                    : "전체",
                value: item,
              }))}
              value={pushYn}
              singleSelect={e => setPushYn(e as TypeYNWithEmpty)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">비회원포함</div>
          <div className="col">
            <RadioBox
              list={[
                {text: "포함", value: "Y"},
                {text: "미포함", value: "N"},
              ]}
              value={includeNonMember}
              setValue={value => setIncludeNonMember(value as TypeYN)}
            />
          </div>
          <div className="col flex-end">
            (테스트발송이 아닌 경우, 비회원 포함 선택시 비회원에게는 발송조건에
            상관없이 무조건 발송됩니다.)
          </div>
        </div>

        <div
          className={["row", styles.prevContainer].join(" ")}
          style={{marginTop: 10}}>
          <div className="col">
            <div style={{flex: 1}}>
              <div className="row">
                <div className="col title">조건부합자 :</div>
                <div className="col">
                  {(prevUserCount?.all_count || 0).toLocaleString()} 명
                </div>
              </div>
              <div className="row">
                <div className="col title">토큰없는 유저 :</div>
                <div className="col">
                  {(prevUserCount?.none_token_count || 0).toLocaleString()} 명
                </div>
              </div>
              <div className="row">
                <div className="col title">비회원 :</div>
                <div className="col">
                  {(prevUserCount?.non_member_count || 0).toLocaleString()} 명
                </div>
              </div>
              <div className="row">
                <div className="col title">발송예상수 :</div>
                <div className="col">
                  {(prevUserCount?.send_count || 0).toLocaleString()} 명
                </div>
              </div>
            </div>
          </div>
          <div className="col flex-end" style={{alignItems: "flex-start"}}>
            <div>
              <Button
                text="예상조회"
                onClick={getPrevUserCount}
                backgroundColor={styleUtils.COLOR.baige}
              />
            </div>
            <div style={{marginLeft: 4}}>
              <Button
                text="엑셀다운로드"
                onClick={memberListExcelDownload}
                backgroundColor={styleUtils.COLOR.blueLight}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col title">예약발송</div>
          <div className="col">
            <CheckBox
              text="선택"
              checked={isReservation}
              // onClick={e => setIsReservation(!isReservation)}
              disabled
            />
          </div>
        </div>
        <>
          {!!isReservation && (
            <>
              <div className="row">
                <div className="col title">예약발송일시</div>
                <div className="col">
                  <div className="row">
                    <div className="col">
                      <InputBox
                        value={reservationDate}
                        type={"date"}
                        onValue={setReservationDate}
                      />
                    </div>
                    <div className="col">
                      <TimePicker
                        value={reservationTime}
                        setValue={setReservationTime}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col flex-end">
                  현재 시간: {nowTime.format("YYYY-MM-DD HH:mm")} (발송예정까지{" "}
                  {!!reservationDateTime && reservationDateTime.isValid()
                    ? timeDiffConvert({
                        beforeTime: nowTime,
                        afterTime: reservationDateTime,
                      })
                    : 0}
                  )
                </div>
              </div>
            </>
          )}
        </>

        <hr />

        <div className="row">
          <div className="col title">테스트발송</div>
          <div className="col">
            <CheckBox
              text="선택"
              checked={test}
              onClick={e => setTest(!test)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">딥링크</div>
          <div className="col">
            <SelectBox
              list={[{label: "미선택", value: ""}, ...(deeplinkArr || [])]}
              value={deeplink}
              singleSelect={value => setDeeplink(value as TDeeplinkValue)}
            />
          </div>
        </div>
        <>
          {(targetList || []).length > 0 && (
            <div className="row">
              <div className="col title">딥링크타겟</div>
              <div className="col">
                <SelectBox
                  list={(targetList || []).map(item => ({
                    value: String(item.no),
                    label: String(item.title || ""),
                  }))}
                  value={targetNo}
                  singleSelect={setTargetNo}
                />
              </div>
            </div>
          )}
        </>
        <div className="row">
          <div className="col title">타이틀</div>
          <div className="col">
            <InputBox value={title} onValue={setTitle} />
          </div>
        </div>
        <div className="row">
          <div className="col title">내용</div>
          <div className="col">
            <TextAreaBox value={content} height={80} onValue={setContent} />
          </div>
        </div>
        <div className="row">
          <div className="col flex-end">
            {/**/}

            {/*<div>*/}
            {/*  <div className="text-right">*/}
            {/*    닉네임 : <span style={{fontWeight: 700}}>%닉네임%</span>*/}
            {/*  </div>*/}
            {/*  <div className="text-right">*/}
            {/*    아이명 : <span style={{fontWeight: 700}}>%아이명%</span>*/}
            {/*  </div>*/}
            {/*  <div className="text-right" style={{marginTop: "8px"}}>*/}
            {/*    (예시:{" "}*/}
            {/*    <span*/}
            {/*      style={{*/}
            {/*        background: styleUtils.COLOR.baige,*/}
            {/*        padding: "2px 4px",*/}
            {/*      }}>*/}
            {/*      %아이명% 보호자님! 또또우유가 재입고되었어요!*/}
            {/*    </span>*/}
            {/*    )*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>

        <hr />

        <div className="row">
          <div className="col title">쿠폰발행</div>
          <div className="col">
            <SelectBox
              list={[
                {no: "", coupon_num: "미선택", title: ""},
                ...couponList,
              ].map(item => ({
                value: String(item.no),
                label: `[${item.coupon_num}] ${item.title}`,
              }))}
              value={couponNo}
              singleSelect={setCouponNo}
            />
          </div>
        </div>
        <>
          {!!couponNo && (
            <>
              <div className="row">
                <div className="col title">즉시발행</div>
                <div className="col">
                  <CheckBox
                    checked={couponImmediateYn === "Y"}
                    text={"즉시발행"}
                    onChange={e =>
                      setCouponImmediateYn(e.target.checked ? "Y" : "N")
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col title">쿠폰사용만료일</div>
                <div className="col">
                  <div className="row">
                    <div className="col">
                      <InputBox
                        value={couponLimitDate}
                        type={"date"}
                        onValue={setCouponLimitDate}
                      />
                    </div>
                    <div className="col">
                      <TimePicker
                        step={1}
                        value={couponLimitTime}
                        setValue={setCouponLimitTime}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col flex-end">
                  현재 시간: {nowTime.format("YYYY-MM-DD HH:mm")} (발송예정까지{" "}
                  {!!couponLimitDateTime && couponLimitDateTime.isValid()
                    ? timeDiffConvert({
                        beforeTime: nowTime,
                        afterTime: couponLimitDateTime,
                      })
                    : 0}
                  )
                </div>
              </div>
              {couponImmediateYn === "N" && (
                <>
                  <div className="row">
                    <div className="col title">팝업 타이틀</div>
                    <div className="col">
                      <InputBox
                        value={couponPopupTitle}
                        onValue={setCouponPopupTitle}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col title">팝업 내용</div>
                    <div className="col">
                      <TextAreaBox
                        value={couponPopupContent}
                        height={50}
                        onValue={setCouponPopupContent}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      </ModalContent>
      <ModalFooter>
        <div>
          <Button
            text="저장"
            onClick={saveMarketingTemp}
            backgroundColor={styleUtils.COLOR.orange}
          />
        </div>
        <div>
          <Button text="닫기" onClick={close} />
        </div>
      </ModalFooter>
    </Modal>
  );
}

function timeDiffConvert({
  beforeTime,
  afterTime,
}: {
  beforeTime: moment.Moment;
  afterTime: moment.Moment;
}) {
  if (!beforeTime.isValid() || !afterTime.isValid()) {
    return "-";
  }

  const diffDay = moment(afterTime).diff(moment(beforeTime), "day");
  const diffHour = moment(afterTime).diff(moment(beforeTime), "hour") % 24;
  const diffMinute = moment(afterTime).diff(moment(beforeTime), "minute") % 60;

  return `${diffDay !== 0 ? `${diffDay}일 ` : ""}${
    diffHour !== 0 ? `${diffHour}시간 ` : ""
  }${diffMinute !== 0 ? `${diffMinute}분` : ""}`;
}
