import React, {useEffect, useState} from "react";
import moment from "moment";
import {
  Button,
  FileUploadButton,
  InputBox,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  SelectBox,
  TextAreaBox,
} from "@components";
import {states} from "@recoils";
import {styleUtils, utils} from "@utils";
import {
  IDBHomeTabList,
  IDBProduct,
  ISection,
  ISectionRequest,
  SECTION_VIEW_TYPE,
  TSectionViewType,
} from "@data";
import {useSetRecoilState} from "recoil";
import {adminAPI} from "utils";
import styles from "./style.module.scss";
import FlatListBox from "../../components/FlatListBox";

interface IProps {
  isVisible: boolean;
  prevData?: ISection;
  close?: () => void;
  refresh?: () => void;
  homeTabList: IDBHomeTabList[];
}

export function DetailModal({
  isVisible,
  prevData,
  close,
  refresh,
  homeTabList,
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [itemList, setItemList] = useState<IDBProduct[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [data, setData] = useState<ISectionRequest>();

  function setClose() {
    setData(undefined);
    setSelectedItems([]);
    close?.();
  }

  async function getItemList() {
    try {
      const {data, success} = await adminAPI.item.apiGetAllItems({});
      if (success) {
        setItemList(data.result);
      }
    } catch (error) {
      console.log("getItemList error", error);
    }
  }

  async function deleteSection() {
    try {
      if (!data?.no) {
        return;
      }
      if (!confirm("섹션을 삭제하시겠습니까?")) {
        return;
      }

      const {success, message} = await adminAPI.section.deleteSection({
        no: data?.no,
      });
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다",
      });
      if (success) {
        setClose();
        refresh?.();
      }
    } catch (error) {
      console.error("deleteSection error", error);
    }
  }

  async function save() {
    try {
      if (!data?.title) {
        return setAlertModal({
          isVisible: true,
          title: "타이틀을 입력해주세요.",
        });
      }

      console.log(data);

      const {success, message} = data?.no
        ? await adminAPI.section.updateSection(data)
        : await adminAPI.section.createSection(data);

      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다",
      });

      if (success) {
        setClose();
        refresh?.();
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  async function copySection() {
    try {
      if (!data?.no) {
        return;
      }
      if (
        !confirm(
          "동일한 섹션을 복제하시겠습니까? 비노출로 복제되며, 새로 생성된 섹션을 수정하셔서 사용하시면 됩니다.",
        )
      ) {
        return;
      }

      const {success, message} = await adminAPI.section.copySection({
        no: data.no,
      });
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다",
      });
      if (success) {
        setClose();
        refresh?.();
      }
    } catch (error) {
      console.error("copySection error", error);
    }
  }

  useEffect(() => {
    if (prevData) {
      setData({
        no: prevData.no,
        title: prevData.title || "",
        sub_title: prevData.sub_title || "",
        openDate: moment(prevData.openDate).isValid()
          ? moment(prevData.openDate).format("YYYY-MM-DD")
          : "",
        closeDate: moment(prevData.closeDate).isValid()
          ? moment(prevData.closeDate).format("YYYY-MM-DD")
          : "",
        item_no: (prevData.items || [])
          .map(item => (item.item_no ? Number(item.item_no) : undefined))
          .filter(utils.isDefined),
        home_tab_no: prevData.home_tab_no,
        view_type: prevData.view_type,
        img_url: prevData.img_url,
        big_img_url: prevData.big_img_url,
        home_title: prevData.home_title,
        home_sub_title: prevData.home_sub_title,
        detail_title: prevData.detail_title,
        detail_content: prevData.detail_content,
      });
      setSelectedItems(
        (prevData.items || [])
          .filter(utils.isDefined)
          .sort((a, b) => {
            if (a.ordering === undefined && b.ordering === undefined) return 0; // 둘 다 undefined
            if (a.ordering === undefined) return 1; // a가 undefined인 경우 뒤로 보냄
            if (b.ordering === undefined) return -1; // b가 undefined인 경우 뒤로 보냄
            return a.ordering - b.ordering; // 둘 다 정의된 경우 정렬
          })
          .map(item => String(item.item_no)),
      );
    } else {
      setData(undefined);
      setSelectedItems([]);
    }
  }, [prevData]);

  useEffect(() => {
    getItemList();
  }, []);

  return (
    <>
      <Modal isVisible={isVisible} width={"700px"} close={setClose}>
        <ModalTitle title="홈섹션상세" isClose close={setClose} />
        <ModalContent>
          <div className="row">
            <div className="col title">타이틀</div>
            <div className="col">
              <TextAreaBox
                value={data?.title}
                onValue={value => setData?.({...data, title: value})}
                height={50}
              />
            </div>
          </div>
          <div className="row">
            <div className="col title">서브 타이틀</div>
            <div className="col">
              <TextAreaBox
                value={data?.sub_title}
                onValue={value => setData?.({...data, sub_title: value})}
                height={50}
              />
            </div>
          </div>
          <div className="row">
            <div className="col title">상품선택</div>
            <div className="col">
              <FlatListBox
                multiple
                list={itemList.map(item => ({
                  value: String(item.no),
                  label:
                    `[${item.partners?.partner_name}] ${item.item_name}` || "",
                }))}
                value={selectedItems}
                orderedValues={selectedItems.map(item => {
                  console.log("orderedValues", item);
                  return item;
                })}
                onOrderChange={newOrder => {
                  setSelectedItems(newOrder);
                  setData({...data, item_no: newOrder.map(Number)});
                }}
                onSelect={values => {
                  setSelectedItems(values as string[]);
                  setData({...data, item_no: (values as string[]).map(Number)});
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col title">탭구분</div>
            <div className="col">
              <SelectBox
                list={[{no: null, name: "홈"}, ...homeTabList].map(item => ({
                  value: String(item.no),
                  label: String(item.name || ""),
                }))}
                value={String(data?.home_tab_no)}
                singleSelect={value =>
                  setData?.({...data, home_tab_no: Number(value)})
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col title">레이아웃</div>
            <div className="col">
              <SelectBox
                list={([1, 2, 3] as TSectionViewType[]).map(type => ({
                  value: String(type),
                  label: SECTION_VIEW_TYPE[type],
                }))}
                value={String(data?.view_type)}
                singleSelect={value =>
                  setData?.({...data, view_type: Number(value)})
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col title">노출일</div>
            <div className="col">
              <div className={["row", "center"].join(" ")}>
                <div className="col">
                  <InputBox
                    type="date"
                    value={data?.openDate}
                    onValue={value => setData?.({...data, openDate: value})}
                  />
                </div>
                <div className="col">~</div>
                <div className="col">
                  <InputBox
                    type="date"
                    value={data?.closeDate}
                    onValue={value => setData?.({...data, closeDate: value})}
                  />
                </div>
              </div>
            </div>
          </div>
          <>
            {data?.view_type === 3 && (
              <>
                <hr />
                <div className="row">
                  <div className="col">
                    아래 항목들은 레이아웃-상품조합일 때만 노출됩니다.
                  </div>
                </div>
                <div className="row">
                  <div className="col title">
                    <div className="buttonCol">
                      <div>
                        썸네일
                        <br />
                        <span className="text-10">(비율 335x178)</span>
                      </div>
                      <div>
                        <FileUploadButton
                          text={"업로드"}
                          height={32}
                          uploadUrl={"/admin/utils/uploadSectionImg"}
                          fileUpload={({files}) =>
                            setData?.({...data, img_url: files?.[0]})
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    {!!data?.img_url && (
                      <img src={data?.img_url} className={styles.logoImg} />
                    )}
                  </div>
                </div>
                <div className="row top">
                  <div className="col title">홈화면 타이틀</div>
                  <div className="col">
                    <TextAreaBox
                      value={data?.home_title}
                      onValue={value => setData?.({...data, home_title: value})}
                      height={50}
                    />
                  </div>
                </div>
                <div className="row top">
                  <div className="col title">홈화면 서브타이틀</div>
                  <div className="col">
                    <TextAreaBox
                      value={data?.home_sub_title}
                      onValue={value =>
                        setData?.({...data, home_sub_title: value})
                      }
                      height={100}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col title">
                    <div className="buttonCol">
                      <div>
                        상세페이지이미지
                        <br />
                        <span className="text-10">(비율 335x280)</span>
                      </div>
                      <div>
                        <FileUploadButton
                          text={"업로드"}
                          height={32}
                          uploadUrl={"/admin/utils/uploadSectionImg"}
                          fileUpload={({files}) =>
                            setData?.({...data, big_img_url: files?.[0]})
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    {!!data?.big_img_url && (
                      <img src={data?.big_img_url} className={styles.logoImg} />
                    )}
                  </div>
                </div>
                <div className="row top">
                  <div className="col title">상세화면 타이틀</div>
                  <div className="col">
                    <TextAreaBox
                      value={data?.detail_title}
                      onValue={value =>
                        setData?.({...data, detail_title: value})
                      }
                      height={50}
                    />
                  </div>
                </div>
                <div className="row top">
                  <div className="col title">상세화면 서브타이틀</div>
                  <div className="col">
                    <TextAreaBox
                      value={data?.detail_content}
                      onValue={value =>
                        setData?.({...data, detail_content: value})
                      }
                      height={100}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        </ModalContent>
        <ModalFooter>
          <div>
            {!!data?.no && (
              <Button
                text="삭제"
                onClick={deleteSection}
                backgroundColor={styleUtils.COLOR.red400}
              />
            )}
          </div>
          <div className="col">
            <div className="row">
              {!!data?.no && (
                <Button
                  text="복제"
                  onClick={copySection}
                  marginHorizontal={4}
                />
              )}
              <Button
                text="저장"
                onClick={save}
                backgroundColor={styleUtils.COLOR.success}
                fontColor={styleUtils.COLOR.white}
              />
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
}
