import {
  Button,
  CheckBox,
  Content,
  ContentLayout,
  InputBox,
  ListTable,
  SearchFilter,
  SelectBox,
} from "@components";
import {
  faArrowCircleDown,
  faArrowCircleUp,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {states} from "@recoils";
import {adminAPI, utils} from "@utils";
import {
  ResultPagingResponse,
  TUpDown,
  TypeYN,
  IDBBanner,
  IBannerRequest,
  IBannerResponse,
  IBannerDetail,
} from "@data";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import {isDefined} from "utils/utils";
import {BannerDetailModal} from "./BannerDetailModal";
import {BannerModal} from "./BannerModal";
import FilterSection from "../../components/FilterSection";
import ToggleSelectBox from "../../components/ToggleSelectBox";

export default function BannerPage() {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [searchText, setSearchText] = useState("");
  const [listData, setListData] =
    useState<ResultPagingResponse<IBannerResponse[]>>();
  const [detailData, setDetailData] = useState<IDBBanner>();
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [bannerDetail, setBannerDetail] = useState<IDBBanner>();
  const [photoList, setPhotoList] = useState<string[]>();
  const [showFilter, setShowFilter] = useState<string>("");
  const [sorting, setSorting] = useState<{
    column: string;
    direction: string;
  }>({
    column: "",
    direction: "ASC",
  });

  async function getList({page = 1}: {page?: number} = {}) {
    try {
      console.log(
        page,
        searchText,
        [showFilter],
        sorting.column,
        sorting.direction,
      );

      const {data, success, message} = await adminAPI.banner.getList({
        page,
        searchtxt: searchText,
        filters: [showFilter],
        sort: sorting.column,
        direction: sorting.direction,
      });
      if (success) {
        setListData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getList error", error);
    }
  }

  async function getDetail({no}: {no: number}) {
    try {
      const {data, success, message} = await adminAPI.banner.getBanner({no});
      if (success) {
        setDetailData(data);
        setPhotoList(
          (data.photos || []).map(item => item.img_url).filter(isDefined),
        );
        setIsDetailOpen(true);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getDetail error", error);
    }
  }

  async function saveBanner() {
    try {
      const params: IBannerRequest = {...detailData, photo_list: photoList};

      const {success, message} = params.no
        ? await adminAPI.banner.updateBanner(params)
        : await adminAPI.banner.createBanner(params);
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다",
      });
      if (success) {
        getList({page: listData?.page?.cur || 1});
      }
    } catch (error) {
      console.error("saveBanner error", error);
    }
  }

  async function deleteBanner() {
    try {
      if (!detailData?.no) {
        return;
      }

      const {success, message} = await adminAPI.banner.deleteBanner({
        no: detailData.no,
      });
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다",
      });
      if (success) {
        getList({page: listData?.page?.cur || 1});
      }
    } catch (error) {
      console.error("deleteBanner error", error);
    }
  }

  async function toggleYN({
    no,
    hide_yn,
    show_category_yn,
  }: {
    no: number;
    hide_yn?: TypeYN;
    show_category_yn?: TypeYN;
  }) {
    try {
      if (!no) {
        return;
      }

      const {success, message} = await adminAPI.banner.updateBanner({
        no,
        hide_yn,
        show_category_yn,
      });
      if (success) {
        getList();
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  async function getBannerDetail({no}: {no: number}) {
    try {
      const {data, success, message} = await adminAPI.banner.getDetail({no});
      if (success) {
        setBannerDetail(data.result);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getBannerDetail error", error);
    }
  }

  async function saveBannerDetail({
    items = [],
    details = [],
  }: {
    items: number[];
    details: IBannerDetail[];
  }) {
    try {
      if (!bannerDetail || !bannerDetail.no) {
        return;
      }

      const params = {
        no: bannerDetail.no,
        items_title: bannerDetail.items_title || "",
        items,
        details,
      };
      const {data, success, message} = await adminAPI.banner.updateDetailBanner(
        params,
      );
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다",
      });
      if (success) {
        getBannerDetail({no: bannerDetail.no});
      }
    } catch (error) {
      console.error("saveBannerDetail error", error);
    }
  }

  async function changeBannerOrdering({no, type}: {no: number; type: TUpDown}) {
    try {
      if (!confirm("순서를 변경하시겠습니까?")) {
        return;
      }

      const {success, message} = await adminAPI.banner.changeOrdering({
        no,
        type,
      });
      if (success) {
        getList({page: listData?.page?.cur || 1});
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("changeBannerOrdering error", error);
    }
  }

  const FilterToggleSelectBox = () => {
    return (
      <ToggleSelectBox
        list={[
          {label: "노출", value: ""},
          {label: "현재 노출", value: "allShow"},
          {label: "비노출", value: "hide"},
          // {label: "상시 노출", value: "anyShow"},
          // {label: "기간제 노출", value: "limitShow"},
          // {label: "카테고리 페이지 노출", value: "categoryShow"},
        ]}
        value={showFilter}
        singleSelect={value => {
          setShowFilter(value || "");
        }}
        placeholder={"노출여부"}
        defaultOpen={true}
      />
    );
  };

  const OrderToggleSelectBox = () => {
    return (
      <ToggleSelectBox
        list={[
          {label: "순번", value: "ordering"},
          {label: "시작일", value: "openDate"},
          {label: "종료일", value: "closeDate"},
          {label: "번호", value: "no"},
        ]}
        value={sorting?.column}
        singleSelect={value => {
          setSorting({
            column: value || "",
            direction: value === "ordering" ? "DESC" : "ASC",
          });
        }}
        placeholder={"정렬 기준"}
        defaultOpen={true}
      />
    );
  };

  const filters = [
    <OrderToggleSelectBox key={1} />,
    <FilterToggleSelectBox key={0} />,
  ];

  useEffect(() => {
    getList(); // 검색 텍스트, 정렬 및 필터링 상태가 변경될 때 리스트를 다시 가져옴
  }, [searchText, showFilter, sorting]);

  return (
    <>
      <ContentLayout title="배너관리">
        <SearchFilter
          left={[
            <SelectBox
              key={2}
              list={[
                {label: "노출여부", value: ""},
                {label: "현재 노출", value: "allShow"},
              ]}
              boxStyle={{width: "120px"}}
              value={showFilter}
              singleSelect={value => {
                setShowFilter(value || "");
              }}
            />,
            <SelectBox
              key={3}
              list={[
                {label: "정렬여부", value: ""},
                {label: "시작일", value: "openDate"},
                {label: "종료일", value: "closeDate"},
                {label: "번호", value: "no"},
              ]}
              boxStyle={{width: "120px"}}
              value={sorting?.column}
              singleSelect={value => {
                setSorting({
                  column: value || "",
                  direction: "DESC",
                });
              }}
            />,
            <InputBox
              key={1}
              value={searchText}
              onValue={setSearchText}
              onEnter={getList}
              rightContent={
                <div style={{display: "flex"}} onClick={() => getList()}>
                  <FontAwesomeIcon icon={faSearch} />
                </div>
              }
            />,
          ]}
          right={[
            <Button
              key={0}
              text="신규등록"
              onClick={() => {
                setDetailData(undefined);
                setPhotoList(undefined);
                setIsDetailOpen(true);
              }}
            />,
          ]}
        />
        <Content>
          <ListTable paging={listData?.page} getList={getList}>
            <thead>
              <tr>
                <th>번호</th>
                <th>
                  홈화면
                  <br />
                  이미지
                </th>
                <th>
                  카테고리
                  <br />
                  이미지
                </th>
                <th>제목</th>
                <th>시작일</th>
                <th>종료일</th>
                <th>노출여부</th>
                <th>
                  카테고리페이지
                  <br />
                  노출
                </th>
                <th>순번</th>
                <th>상세페이지</th>
              </tr>
            </thead>
            <tbody>
              {(listData?.result || []).map((item, index) => {
                return (
                  <tr
                    key={index}
                    onClick={() => !!item.no && getDetail({no: item.no})}>
                    <td>{item.no}</td>
                    <td>
                      {!!item.big_img_url && <img src={item.big_img_url} />}
                    </td>
                    <td>
                      {!!item.thumbnail_img && <img src={item.thumbnail_img} />}
                    </td>
                    <td>
                      {item.title}
                      {!!item.bold_title && (
                        <span className="text-bold" style={{marginLeft: "4px"}}>
                          {item.bold_title}
                        </span>
                      )}
                    </td>
                    <td>
                      {item.always_open_yn === "Y" ? (
                        <>
                          상시노출
                          <br />
                          {item.always_open_title}
                        </>
                      ) : (
                        <>
                          {moment(item.openDate).isValid() && (
                            <>
                              {moment(item.openDate).format("YYYY-MM-DD")}
                              {!item.isStart && (
                                <>
                                  <br />
                                  <span className="text-12">(시작전)</span>
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </td>
                    <td>
                      {item.always_open_yn === "Y" ? (
                        ""
                      ) : (
                        <>
                          {moment(item.closeDate).isValid() && (
                            <>{moment(item.closeDate).format("YYYY-MM-DD")}</>
                          )}
                        </>
                      )}
                    </td>
                    <td onClick={e => utils.handleClickBubble(e)}>
                      {item.isEnd ? (
                        <>종료</>
                      ) : (
                        <CheckBox
                          checked={item.hide_yn === "N"}
                          text={item.hide_yn === "N" ? "노출" : "숨김"}
                          isToggle
                          onChange={e => {
                            if (!item.no) {
                              return;
                            }
                            toggleYN({
                              no: item.no,
                              hide_yn: item.hide_yn === "N" ? "Y" : "N",
                            });
                          }}
                        />
                      )}
                    </td>
                    <td onClick={e => utils.handleClickBubble(e)}>
                      {item.isEnd ? (
                        <>종료</>
                      ) : (
                        <CheckBox
                          checked={item.show_category_yn === "Y"}
                          text={item.show_category_yn === "Y" ? "노출" : "숨김"}
                          isToggle
                          onChange={e => {
                            if (!item.no) {
                              return;
                            }
                            toggleYN({
                              no: item.no,
                              show_category_yn:
                                item.show_category_yn === "Y" ? "N" : "Y",
                            });
                          }}
                        />
                      )}
                    </td>
                    <td onClick={e => utils.handleClickBubble(e)}>
                      <div className="row" style={{minWidth: "50px"}}>
                        <div className="col">
                          {!item.isEnd &&
                            item.hide_yn === "N" &&
                            item.del_yn === "N" &&
                            (item.ordering || 0) > 1 && (
                              <FontAwesomeIcon
                                icon={faArrowCircleUp}
                                onClick={() => {
                                  if (item.no) {
                                    changeBannerOrdering({
                                      no: item.no,
                                      type: "up",
                                    });
                                  }
                                }}
                              />
                            )}
                        </div>
                        <div className="col">{item.ordering || "-"}</div>
                        <div className="col">
                          {!item.isEnd &&
                            item.hide_yn === "N" &&
                            item.del_yn === "N" && (
                              <FontAwesomeIcon
                                icon={faArrowCircleDown}
                                onClick={() => {
                                  if (item.no) {
                                    changeBannerOrdering({
                                      no: item.no,
                                      type: "down",
                                    });
                                  }
                                }}
                              />
                            )}
                        </div>
                      </div>
                    </td>
                    <td onClick={e => utils.handleClickBubble(e)}>
                      <Button
                        text="상세"
                        onClick={() =>
                          !!item.no && getBannerDetail({no: item.no})
                        }
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </ListTable>
        </Content>
      </ContentLayout>
      <BannerModal
        isVisible={isDetailOpen}
        data={detailData}
        setData={setDetailData}
        photoList={photoList}
        setPhotoList={setPhotoList}
        save={saveBanner}
        deleteBanner={deleteBanner}
        close={() => {
          setDetailData(undefined);
          setIsDetailOpen(false);
          setPhotoList(undefined);
        }}
      />
      <BannerDetailModal
        isVisible={!!bannerDetail}
        data={bannerDetail}
        close={() => setBannerDetail(undefined)}
        setData={setBannerDetail}
        save={saveBannerDetail}
      />
    </>
  );
}
