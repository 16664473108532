import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  ContentLayout,
  Content,
  InputBox,
  ListTable,
  SearchFilter,
  SelectBox,
  Button,
} from "@components";
import {
  ResultPagingResponse,
  TypeYNWithEmpty,
  IMemberQnaListResponse,
  IMemberResponse,
  IDBFaqGroup,
} from "@data";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import {states} from "@recoils";
import {adminAPI, styleUtils, utils} from "@utils";
import {MemberQnaDetailModal} from "./MemberQnaDetailModal";
import styles from "./style.module.scss";
import {MemberDetailModal} from "pages/MemberPage/MemberDetailModal";

const answerSelectList: {
  label: string;
  value: TypeYNWithEmpty;
}[] = [
  {label: "구분", value: ""},
  {label: "답변완료", value: "Y"},
  {label: "답변미완료", value: "N"},
];

export default function MemberQnaPage() {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [groupList, setGroupList] = useState<IDBFaqGroup[]>([]);
  const [answerYn, setAnswerYn] = useState<TypeYNWithEmpty>("");
  const [searchText, setSearchText] = useState("");
  const [listData, setListData] = useState<
    ResultPagingResponse<{
      list?: IMemberQnaListResponse[];
      count?: {allCnt?: number; notCnt?: number};
    }>
  >();
  const [detailData, setDetailData] = useState<IMemberQnaListResponse>();
  const [memberDetail, setMemberDetail] = useState<IMemberResponse>();
  const [groupNo, setGroupNo] = useState<number>();
  const [curPage, setCurPage] = useState(
    localStorage.getItem("memberQnaPage")
      ? Number(localStorage.getItem("memberQnaPage"))
      : 1,
  );

  useEffect(() => {
    localStorage.setItem("memberQnaPage", String(curPage));
  }, [curPage]);

  async function getFaqGroup() {
    try {
      const {success, data} = await adminAPI.faq.apiGetFaqGroup();
      if (success) {
        setGroupList(data.result);
      }
    } catch (error) {
      console.error("getFaqGroup error", error);
    }
  }

  async function getList({page = curPage}: {page?: number} = {}) {
    try {
      setCurPage(page);
      const {data, success, message} =
        await adminAPI.member.apiGetMemberQnAList({
          page: page,
          searchtxt: searchText,
          answer_yn: answerYn,
          group_no: groupNo,
        });
      if (success) {
        setListData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getList error", error);
    }
  }

  async function getDetail({no}: {no: number}) {
    try {
      const {data, success, message} =
        await adminAPI.member.apiGetMemberQnADetail({
          no,
        });
      if (success) {
        setDetailData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getDetail error", error);
    }
  }

  async function updateDetail({answer}: {answer: string}) {
    try {
      if (!detailData || !detailData.no) {
        return;
      }

      const {success, message} = await adminAPI.member.apiUpdateMemberQnaAnswer(
        {
          no: detailData.no,
          admin_no: 1,
          answer,
        },
      );
      if (success) {
        setDetailData(undefined);
        getList({page: listData?.page?.cur || 1});
        setAlertModal({
          isVisible: true,
          title: message || "답변등록 완료되었습니다.",
        });
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("updateDetail error", error);
    }
  }

  async function getMemberDetail(no: number) {
    try {
      const {data, success, message} = await adminAPI.member.getDetail({
        member_no: no,
      });
      if (success) {
        setMemberDetail(data.result);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getDetail error", error);
    }
  }

  useEffect(() => {
    getFaqGroup();
  }, []);

  useEffect(() => {
    getList();
  }, [answerYn, groupNo]);

  return (
    <>
      <ContentLayout title="1:1 문의관리">
        <SearchFilter
          left={[
            <SelectBox
              key={1}
              list={answerSelectList}
              value={answerYn}
              boxStyle={{width: 100}}
              singleSelect={e => setAnswerYn(e as TypeYNWithEmpty)}
            />,
            <SelectBox
              key={2}
              list={[{no: undefined, title: "전체"}, ...groupList].map(
                item => ({
                  value: String(item.no || ""),
                  label: item.title || "",
                }),
              )}
              boxStyle={{width: 120}}
              value={String(groupNo)}
              singleSelect={value => setGroupNo(Number(value))}
            />,
            <InputBox
              key={1}
              value={searchText}
              onValue={setSearchText}
              onEnter={getList}
              rightContent={
                <div style={{display: "flex"}} onClick={() => getList()}>
                  <FontAwesomeIcon icon={faSearch} />
                </div>
              }
            />,
          ]}
        />
        <Content>
          <ListTable paging={listData?.page} getList={getList}>
            <thead>
              <tr>
                <th>고객명(닉네임)</th>
                <th>구분</th>
                <th>제목</th>
                <th>문의일시</th>
                <th>답변여부</th>
              </tr>
            </thead>
            <tbody>
              {(listData?.result.list || []).map((item, index) => {
                const isComplete = !!item.answer;

                return (
                  <tr
                    key={index}
                    onClick={() => !!item.no && getDetail({no: item.no})}>
                    <td onClick={utils.handleClickBubbleAndCapture}>
                      {item.member?.name} ({item.member?.nickname || "-"})
                      <br />
                      <div style={{display: "inline-block"}}>
                        <Button
                          text="상세"
                          onClick={() =>
                            !!item.member_no && getMemberDetail(item.member_no)
                          }
                          backgroundColor={styleUtils.COLOR.success}
                          fontColor={styleUtils.COLOR.white}
                          paddingVertical={1}
                          paddingHorizontal={6}
                          fontSize={12}
                        />
                      </div>
                    </td>
                    <td>{item.group?.title}</td>
                    <td>{item.title}</td>
                    <td>{moment(item.createdAt).format("YYYY-MM-DD HH:mm")}</td>
                    <td>
                      <div
                        className={[
                          styles.badge,
                          ...[isComplete && styles.active],
                        ].join(" ")}>
                        {isComplete ? "완료" : "미완료"}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </ListTable>
        </Content>
      </ContentLayout>
      {!!detailData && (
        <MemberQnaDetailModal
          detailData={detailData}
          close={() => setDetailData(undefined)}
          updateDetail={updateDetail}
        />
      )}
      <MemberDetailModal
        isVisible={!!memberDetail}
        close={() => setMemberDetail(undefined)}
        member={memberDetail}
      />
    </>
  );
}
