import React from "react";
import Pagination from "components/Pagination";
import {IPage} from "@data";
import styles from "./style.module.scss";

interface IProps {
  children: JSX.Element | JSX.Element[];
  paging?: IPage;
  getList?: ({page}: {page: number}) => void;
  className?: string;
}

export default function ListTable({
  children,
  paging,
  getList,
  className = "",
}: IProps) {
  return (
    <div className={[styles.container, className].join(" ")}>
      <div style={{overflowX: "auto"}} className={styles["table-wrapper"]}>
        <table className={styles.table}>{children}</table>
      </div>
      {!!paging && paging.maxpage > 1 && (
        <Pagination paging={paging} getList={getList} />
      )}
    </div>
  );
}
