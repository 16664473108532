import React, {useState, useRef, useEffect} from "react";
import styles from "./style.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

interface ListItem {
  value: string;
  label: string;
}

interface FlatListBoxProps {
  list: ListItem[];
  value?: string[] | string;
  multiple?: boolean;
  orderedValues?: string[];
  onOrderChange?: (newOrder: string[]) => void;
  placeholder?: string;
  onSelect: (data: string | string[]) => void;
  renderItem?: (
    item: ListItem,
    isSelected: boolean,
    onClick: () => void,
  ) => JSX.Element;
  checkedRenderItem?: (
    item: ListItem,
    onRemove: () => void,
    index: number,
    moveUp: () => void,
    moveDown: () => void,
  ) => JSX.Element;
  disabled?: boolean;
  className?: string;
}

export default function FlatListBox({
  list,
  value,
  multiple = false,
  orderedValues,
  onOrderChange, // 순서 변경 콜백
  placeholder = "Search...",
  onSelect,
  renderItem,
  checkedRenderItem,
  disabled = false,
  className = "",
}: FlatListBoxProps) {
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedValues, setSelectedValues] = useState<string[]>(
    Array.isArray(value) ? value : value ? [value] : [],
  );

  const dropdownRef = useRef<HTMLDivElement>(null); // 드롭다운 참조

  const filteredList = list.filter(item =>
    item.label.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  useEffect(() => {
    // 외부에서 `orderedValues`가 변경되었을 때 동기화
    if (orderedValues) {
      setSelectedValues(orderedValues);
    }
  }, [orderedValues]);

  useEffect(() => {
    // 드롭다운 외부 클릭 시 닫기
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleSelect(itemValue: string) {
    if (disabled) return;
    if (multiple) {
      const newValues = selectedValues.includes(itemValue)
        ? selectedValues.filter(val => val !== itemValue)
        : [...selectedValues, itemValue];
      setSelectedValues(newValues);
      onSelect(newValues);
    } else {
      setSelectedValues([itemValue]);
      onSelect(itemValue);
    }
    setSearchTerm(""); // 선택 후 검색어 초기화
    setShowDropdown(false); // 드롭다운 닫기
  }

  function handleRemove(itemValue: string) {
    const newValues = selectedValues.filter(val => val !== itemValue);
    setSelectedValues(newValues);
    onSelect(newValues);
  }

  function handleMoveUp(index: number) {
    if (index > 0) {
      const newOrder = [...selectedValues];
      [newOrder[index - 1], newOrder[index]] = [
        newOrder[index],
        newOrder[index - 1],
      ];
      setSelectedValues(newOrder);
      onOrderChange?.(newOrder); // 외부 콜백 호출
    }
  }

  function handleMoveDown(index: number) {
    if (index < selectedValues.length - 1) {
      const newOrder = [...selectedValues];
      [newOrder[index], newOrder[index + 1]] = [
        newOrder[index + 1],
        newOrder[index],
      ];
      setSelectedValues(newOrder);
      onOrderChange?.(newOrder); // 외부 콜백 호출
    }
  }

  return (
    <div
      className={`${styles.flatBoxContainer} ${className}`}
      ref={dropdownRef} // 드롭다운을 참조
    >
      <div className={styles.container}>
        <input
          type="text"
          className={styles.searchInput}
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          onFocus={() => setShowDropdown(true)} // 포커스 시 드롭다운 표시
          placeholder={placeholder}
          disabled={disabled}
        />
        {showDropdown && (
          <ul className={styles.dropdown}>
            {filteredList.map(item => {
              const isSelected = selectedValues.includes(item.value);
              if (renderItem) {
                return renderItem(item, isSelected, () =>
                  handleSelect(item.value),
                );
              }
              return (
                <li
                  key={item.value}
                  className={`${styles.listItem} ${
                    isSelected ? styles.selected : ""
                  }`}
                  onClick={() => handleSelect(item.value)}>
                  {item.label}
                </li>
              );
            })}
          </ul>
        )}
      </div>
      <div className={styles.listWrapper}>
        {selectedValues.map((value, index) => {
          const selectedItem = list.find(item => item.value === value);
          if (selectedItem && checkedRenderItem) {
            return checkedRenderItem(
              selectedItem,
              () => handleRemove(selectedItem.value),
              index,
              () => handleMoveUp(index),
              () => handleMoveDown(index),
            );
          } else if (selectedItem) {
            return (
              <div className={styles.selectedItem} key={index}>
                <div>{selectedItem.label}</div>
                <div>
                  <button onClick={() => handleMoveUp(index)}>
                    <FontAwesomeIcon icon={faArrowUp} />
                  </button>
                  <button onClick={() => handleMoveDown(index)}>
                    <FontAwesomeIcon icon={faArrowDown} />
                  </button>
                  <button onClick={() => handleRemove(selectedItem.value)}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}
