import {ContentLayout, TabList, ITabList} from "@components";
import React, {useState} from "react";
import {DashBoardHomeView} from "./DashBoardHomeView";
import {DashBoardOrderView} from "./DashBoardOrderView";
import {DashBoardReviewView} from "./DashBoardReviewView";
import {DashBoardSignupView} from "./DashBoardSignupView";
import styles from "./style.module.scss";

type TTabs = "home" | "review" | "signup" | "order";
const tabs: ITabList<TTabs>[] = [
  {tab: "home", title: "홈"},
  {tab: "order", title: "주문"},
  {tab: "review", title: "리뷰"},
  {tab: "signup", title: "회원가입"},
];

export default function DashboardPage() {
  const [tab, setTab] = useState<TTabs>("home");

  return (
    <>
      <ContentLayout title="DASHBOARD">
        <TabList tabList={tabs} setTab={setTab} activeTab={tab} />
        <>
          <div className={styles.dashboardWrapper}>
            {tab === "home" && <DashBoardHomeView />}
            {tab === "order" && <DashBoardOrderView />}
            {tab === "review" && <DashBoardReviewView />}
            {tab === "signup" && <DashBoardSignupView />}
          </div>
        </>
      </ContentLayout>
    </>
  );
}
